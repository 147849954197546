import { ButtonsBlock, DoctorIsInDBBlock, DoctorIsNotInDBBlock, Header, SnilsCheckBlock } from './Features';
import { DoctorSnilsCheckStatus } from './AddDoctorPage.types';
import { DoctorsInfoPage } from '../DoctorInfoPage/DoctorsInfoPage';
import { PageMode } from '../DoctorInfoPage/DoctorsInfoPage.types';
import { useAddDoctorPage } from './AddDoctorPage.hooks';
import NavSubMenu from '../../NavSubMenu';
import React from 'react';
import styles from './styles.module.scss';

export const AddDoctorPage = () => {
  const {
    doctorSnilsCheckStatus,
    doctorSnils,
    setDoctorSnils,
    isCheckButtonDisabled,
    handleCancelButtonOnClick,
    handleCheckButtonOnClick,
    doctorFullName,
    doctorSpecializations,
    doctorImageSrc,
    handleAddDoctorToClinicButtonOnClick,
    handleWatchDoctorProfileOnClick,
    isAdding,
    doctorMiddleName,
    doctorLastName,
    doctorFirstName,
    doctorPhotoId,
    doctorDocuments,
    doctorEducation,
    doctorExperience,
    doctorCategory,
    doctorGender,
    doctorBirthDay,
    doctorAcademicDegree
  } = useAddDoctorPage();

  if (isAdding) {
    return (
      <DoctorsInfoPage
        pageMode={PageMode.Creating}
        initialFieldsState={{
          snils: doctorSnils,
          firstname: doctorFirstName,
          middlename: doctorMiddleName,
          surname: doctorLastName,
          showInMilaServices: true,
          photoId: doctorPhotoId,
          documents: doctorDocuments,
          education: doctorEducation,
          degree: doctorAcademicDegree,
          category: doctorCategory,
          gender: doctorGender,
          experience: doctorExperience,
          birthdate: doctorBirthDay
        }}
      />
    );
  }

  return (
    <>
      <NavSubMenu />
      <div className={styles.body}>
        <Header />
        <SnilsCheckBlock doctorSnils={doctorSnils} setDoctorSnils={setDoctorSnils} />
        <ButtonsBlock
          handleCheckButtonOnClick={handleCheckButtonOnClick}
          handleCancelButtonOnClick={handleCancelButtonOnClick}
          isCheckButtonDisabled={isCheckButtonDisabled}
        />
        {doctorSnilsCheckStatus === DoctorSnilsCheckStatus.CheckedAndNotExistsInDB && (
          <DoctorIsNotInDBBlock addDoctorToClinicButtonOnClick={handleAddDoctorToClinicButtonOnClick} />
        )}
        {doctorSnilsCheckStatus === DoctorSnilsCheckStatus.CheckedAndExistsInCurrentMedOrganization && (
          <DoctorIsInDBBlock
            doctorFullName={doctorFullName}
            doctorSpecializations={doctorSpecializations}
            doctorImageSrc={doctorImageSrc}
            headerText="Специалист уже добавлен в вашу клинику"
            buttonText="Посмотреть профиль врача"
            buttonOnClick={handleWatchDoctorProfileOnClick}
          />
        )}
        {doctorSnilsCheckStatus === DoctorSnilsCheckStatus.CheckedAndNotExistsInCurrentMedOrganization && (
          <DoctorIsInDBBlock
            doctorFullName={doctorFullName}
            doctorSpecializations={doctorSpecializations}
            doctorImageSrc={doctorImageSrc}
            headerText="Специалист уже есть в нашей базе"
            buttonText="Добавить врача в свою клинику"
            buttonOnClick={handleAddDoctorToClinicButtonOnClick}
          />
        )}
      </div>
    </>
  );
};
