import {
  MilaDoctorCategory,
  MilaGender,
  PracticeDoctorDocument,
  PracticeDoctorEducation
} from "../../../../common/types";
import { Dayjs } from "dayjs";

export enum PageMode {
  Editing,
  Creating,
}

export type Props = {
  pageMode: PageMode;
  initialFieldsState?: {
    snils?: string;
    surname?: string;
    firstname?: string;
    middlename?: string;
    showInMilaServices?: boolean;
    photoId?: string;
    documents?: PracticeDoctorDocument[];
    education?: PracticeDoctorEducation[];
    gender?: MilaGender | null;
    degree?: string | null;
    experience?: Dayjs | null;
    birthdate?: Dayjs | null;
    category?: MilaDoctorCategory | null;
  };
};

export type AcademicDegrees = {
  label: string;
  value: string;
}[];

export type ExtendedFile = {
  fileId: string;
  file: File;
};

export type GetOrganizationInfoBySpecialityPositionId = {
  positionName: string;
  specialityName: string;
  specialityFerId: string;
};
