import {Branch} from "src/common/types";
import {DownCaretIcon} from "src/common/Icons/icons";
import {Layout, Select, Typography} from "antd";
import {getPreparedBranches} from "src/common/utils/utils";
import {useAppSelector} from "src/app/hooks";
import React, {useEffect, useState} from "react";
import {useGetPracticeBranchesQuery, useGetSessionToReportsByBranchIdQuery} from "src/app/services/api";
import TMKReportsWidget from "./TMKReportsWidget/TMKReportsWidget";
import styles from './styles.module.scss';

export const ReportsPage = () => {
    const {data: sessionData, isFetching, isLoading} = useGetSessionToReportsByBranchIdQuery(
        "",
        {refetchOnMountOrArgChange: true}
    );

    return (
        <Layout className={`${styles.ScheduleWrapper}`}>
            {
                sessionData && !isFetching && !isLoading &&
                <TMKReportsWidget sessionId={sessionData}/>
            }
        </Layout>
    );
};
