import { DefaultOptionType } from 'rc-select/lib/Select';
import { useGetCompositeProfilesQuery } from 'src/app/services/apiDictionary';
import FloatLabel from 'src/common/components/FloatLabel';
import React, { useMemo } from 'react';
import WidthFixedSelect from 'src/common/components/WidthFixedSelect';
import useSelectorSortFilter from 'src/common/hooks/useSelectorSortFilter';

type PropsType = {
  currentValue: string | null;
  onSelect: (value: string, option?: DefaultOptionType | DefaultOptionType[]) => void;
  disabled?: boolean;
};

export const CompositeProfilesSelect: React.FC<PropsType> = ({ currentValue, disabled = false, onSelect }) => {
  const { data: profilesList } = useGetCompositeProfilesQuery('');

  const specOptions = useMemo(
    () => profilesList?.map((item) => ({ label: `${item.name} (код ${item.code})`, value: item.code })) ?? [],
    [profilesList],
  );

  const { filterOptions, sortOptions } = useSelectorSortFilter();

  return (
    <FloatLabel label="Составной профиль помощи *" fixSmall={!!currentValue}>
      <WidthFixedSelect
        disabled={disabled}
        options={specOptions}
        showSearch={true}
        filterSort={sortOptions}
        filterOption={filterOptions}
        value={currentValue}
        onChange={(value, option) => onSelect(value, option)}
      ></WidthFixedSelect>
    </FloatLabel>
  );
};
