import {
  AcademicDegree,
  MilaDoctorCategory,
  MilaGender,
  PracticeDoctorDocument,
  PracticeDoctorEducation
} from "../../../../common/types";

export enum DoctorSnilsCheckStatus {
  IsNotChecked,
  CheckedAndNotExistsInDB,
  CheckedAndExistsInCurrentMedOrganization,
  CheckedAndNotExistsInCurrentMedOrganization,
}

export type DoctorSearchResultModel = {
  doctorId: string;
  doctorPhotoId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: MilaGender;
  academicDegree:  {
    idMilaDoctorAcademicDegree: string;
    name: string;
    description: string;
  };
  experience: Date;
  birthDay: Date;
  category: MilaDoctorCategory;
  documents?: PracticeDoctorDocument[];
  education?: PracticeDoctorEducation[];
  medOrganizations: {
    medOrganizationId: string;
    doctorSpecializations: string[];
    medOrganizationDoctorId: string;
  }[];
};
