import { ColumnTitle } from 'antd/es/table/interface';
import { ColumnType } from 'antd/es/table';
import { Dayjs } from 'dayjs';
import React from 'react';

export declare type DataIndex = string | number | readonly (string | number)[];

export type UserRole = 0 | 1 | 2 | 3;

export type MilaGender = 0 | 1;

export type MilaDoctorCategory = 0 | 1 | 2 | -99;

export type MilaDoctorEducationType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | -99;

export enum AppointmentType {
  OnSite = 0,
  Tmk = 2,
  AtHome = 1,
}

export interface Appointment {
  appointmentId: string;
  cost: string;
  created: Dayjs;
  isMisConfirmed: boolean;
  medOrganizationFullName: string;
  medOrganizationId: string;
  medOrganizationInn: string;
  medOrganizationNetworkName: string;
  medOrganizationShortName: string;
  milaId: string;
  patientBirthDay: Dayjs;
  patientEmail: string;
  patientFullName: string;
  patientId: string;
  patientPhone: string;
  specialistFullName: string;
  specialization: string;
  specializationServiceMisName: string;
  visitEndDate: Dayjs;
  visitStartDate: Dayjs;
  rating: number;
  comment: string;
  doctorReview: DoctorReview;
  appointmentType: AppointmentType;
}

export interface RecordsResponse<T> {
  data: T[];
  appointmentsBatch: T[];
  total: number;
}

export interface DoctorArguments {
  practiceDoctorId: string;
  practiceId: string;
}

export interface DoctorEducationArguments extends DoctorArguments {
  practiceDoctorEducationId: string;
}

export interface DocumentArguments {
  documentId: string;
  practiceId: string;
}

export interface RecordsArguments {
  sortType: string;
  offset: number;
  count: number;
  branchId?: string;
  doctorName?: string;
  patientName?: string;
}

export interface PromotionsRequestArgs {
  sortType: string;
  offset: number;
  count: number;
  branchId: string;
}

export interface WidgetArguments {
  networkCode: string;
  businessCode: string;
}

export interface EmailsArguments {
  practiceId: string;
  emails: string[];
}

export interface UserAccessArguments extends RecordsArguments {
  branchId: string;
}

export interface CallMeBackRequest {
  comment: string;
  created: Dayjs;
  idMilaWidgetCallMeBackRequest: string;
  milaId: string;
  name: string;
  phoneNumber: string;
  status: number;
  whenCallback: string;
}

export interface WaitingListRequest {
  milaId: string;
  idMilaUserPatientPutMeOnTheWaitingListRequest: string;
  status: number;
  comment: string;
  created: Dayjs;
  fio: string;
  birthDay: Dayjs;
  phoneNumber: string;
  email: string;
  doctorFio: string;
  doctorSpeciality: string;
  branchName: string;
}

export interface TmkRequest {
  appointmentId: string;
  milaId: string;
  created: Dayjs;
  isMisConfirmed: boolean;
  visitStartDate: Dayjs;
  visitEndDate: Dayjs;
  cost: string;
  patientId: string;
  patientFullName: string;
  patientBirthDay: string | Dayjs;
  patientPhone: string;
  patientEmail: string;
  specialistFullName: string;
  specialization: string;
  specializationServiceMisName: string;
  medOrganizationId: string;
  medOrganizationInn: string;
  medOrganizationShortName: string;
  medOrganizationFullName: string;
  medOrganizationNetworkName: string;
  paymentStatus: string;
  doctorReview: DoctorReview;
}

export interface DoctorReview {
  score: number;
  comment: string;
}

export interface EditableColumnType<T> extends Omit<ColumnType<T>, 'onCell'> {
  editable: boolean;
  onCell: (record: T) => {
    record: any;
    inputType: string;
    dataIndex: DataIndex | undefined;
    title: ColumnTitle<T> | string | undefined;
    editing: boolean;
  };
}
export type EditableColumnsType<T> = EditableColumnType<T>[];

export interface CallMeBackArgs {
  comment: string;
  id: string;
  status: number;
}
export interface Patient {
  milaId: string;
  fio: string;
  birthDay: string;
  gender: MilaGender;
  phoneNumber: string;
  email: string;
  appointmentsCount: number;
  lastAppointmentDate: string;
  lastAppointmentPlace: string;
  lastAppointmentDoctor: string;
  lastAppointmentSpeciality: string;
}

export interface BranchInWidgetEntity {
  medOrganizationId: string;
  medOrganizationShortName: string;
  showInWidget: boolean;
}

export interface PracticeService {
  specialityId: string;
  serviceId: string;
  serviceNameForPatients: string;
  cost: number;
  isOnSite: boolean;
  isTmk: boolean;
  isAtHome: boolean;
  showInWidget: boolean;
  serviceKindId: string;
  compositeProfileId: string;
}

export type NewPracticeService = {
  specialityFerId: string;
  nameForPatient: string;
  cost: number;
  isOnSite: boolean;
  isTmk: boolean;
  isAtHome: boolean;
  serviceKindId: string;
  compositeProfileId: string;
  showInWidget: boolean;
};

export interface PracticeSpecialityService {
  medOrganizationId: string;
  specialityFerId: string;
  specialityName: string;
  specialityServicesCount: number;
  services: PracticeService[];
}

export interface Branch {
  branchId: string;
  shortName: string;
  useTmData: string;
  isShowInPatientAccountServiceEnabled: boolean;
  isTmkServiceEnabled: boolean;
  isWidgetServiceEnabled: boolean;
  isScheduleServiceEnabled: boolean;
  isClinicalResearchServiceEnabled: boolean;
}

export interface BranchGeneralInfo {
  branchId: string;
  fullName: string;
  shortName: string;
  description: string;
  timeZone: string;
  workingHours: string;
  facadePhoto: string | null;
  extraFacadePhoto: string | null;
  interiorPhoto: string | null;
  extraInteriorPhoto: string | null;
  ownershipType: OwnershipType | null;
  providesAdults: boolean;
  providesPediatrics: boolean;
  websiteUrl: string;
  idLogo: string | null;
}

export interface BranchPaymentInfo {
  branchId: string;
  paymentLogin: string;
  paymentPassword: string;
  paymentToken: string;
}

export enum OwnershipType {
  Government = 1,
  Corporation = 2,
  Individual = 3,
}

export interface BranchContactInfo {
  branchId: string;
  address: string;
  nearestSubway: string;
  nearestSubwayDistanceMeters: string;
  district: string;
  latitude: string;
  longitude: string;
  phoneNumbersForPatients: string[];
  addressForPatients: string;
  howToGetThere: string;
  parkingDescription: string;
  state: string;
  region: string;
  locality: string;
  street: string;
  building: string;
}

export interface BranchDocuments {
  practiceDocumentId: string;
  name: string;
  size: string;
  mimeType: string;
}

export interface PracticeSpecialty {
  specialityFederalId: string;
  specialityMainMisName: string;
  specialityMisNames: string[];
  specialityWidgetMarketingName: string;
}

export interface UserAccessResponse {
  data: PracticeUserAccess[];
  total: number;
  userAccessBatch: PracticeUserAccess[];
}

export interface PracticeUserAccess {
  userId: string;
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  snils: string;
  phone: string;
  isSelf: boolean;
  position: string;
  role: UserRole;
  status: string;
  hasAccessToBranches: string[];
  hasAccessToBranchesCount: number;
  hasAccessToPages: string[];
}

export interface PracticeDoctorList {
  practiceDoctorId: string;
  practiceDoctorPhotoId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  specializations: string[];
  appointmentsCount: number;
  specialityServicesCount: number;
  isVisibleInWidget: boolean;
}

export interface PracticeDoctorGeneralInfo {
  practiceDoctorId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDay: string;
  gender: MilaGender;
  category: MilaDoctorCategory;
  position: string;
  snils: string;
  degree: string;
  experience: string;
  idPhotoFile: string;
  academicDegreeId: string;
  availableAcademicDegrees: AcademicDegree[];
  specializations: PracticeDoctorSpecialization[];
  aboutDoctor: string;
  hasAccessToDoctorPortal: boolean;
  hasAccessToDoctorPortalWithDoctor: boolean;
  hasAccessToOtherDoctorPortal: boolean;
}

export interface AcademicDegree {
  id: string;
  name: string;
}

export interface PracticeDoctorSpecialization {
  doctorSpecializationId: string;
  doctorSpecializationName: string;
  canServeAdults: boolean;
  canServeChildren: boolean;
}

export interface DoctorEducationType {
  code: MilaDoctorEducationType;
  name: string;
}

export interface PracticeContactInfo {
  branchId: string;
  address: string;
  nearestSubway: string;
  nearestSubwayDistanceMeters: string;
  district: string;
  latitude: string;
  longitude: string;
  phoneNumbersForPatients: string[];
  addressForPatients: string;
  howToGetThere: string;
  parkingDescription: string;
}

export interface PracticeDoctorContactInfo {
  phone: string;
  email: string;
}

export interface PracticeDoctorVisibility {
  showInMilaServices: boolean;
}

export interface PracticeDoctorDocument {
  practiceDoctorDocumentId: string;
  name: string;
  size: string;
  mimeType: string;
}

export interface PracticeDoctorEducation {
  idDoctorEducation: string;
  institution: string;
  educationType: MilaDoctorEducationType;
  speciality: string;
  educationEndDate: string;
}

export interface PracticeDoctorSpecializationWithServices {
  specialityMainMisName: string;
  specialityMisNames: string[];
  //specializationId: string;
  //specializationNameFromMis: string;
  specializationServicesCount: number;
  services: PracticeDoctorSpecializationService[];
}

export interface PracticeDoctorSpecializationService {
  specializationId: string;
  serviceId: string;
  serviceNameFromMis: string;
  serviceNameForPatients: string;
  cost: number;
  patientAgeFrom: number;
  patientAgeTo: number;
  isOnSite: boolean;
  isTmk: boolean;
  isAtHome: boolean;
  showInWidget: boolean;
}

export interface PracticeWidgetGeneralSettings {
  practiceNameInWidget: string;
  practicePhoneInWidget: string;
  practiceEmailInWidget: string;
  practiceLogoStaticFileId: string;
  practiceDoctorsWidgetHeight: string;
  practiceDoctorsWidgetWidth: string;
  practiceBranchesVisibilityOnWidget: PracticeBranchVisibilityOnWidget[];
}

export interface PracticeBranchVisibilityOnWidget {
  medOrganizationId: string;
  medOrganizationShortName: string;
  showInWidget: boolean;
}

export interface PracticeWidgetInfoBanner {
  showInWidget: boolean;
  title: string;
  description: string;
  buttonTitle: string;
  buttonUrl: string;
  backGroundFileId: string;
}

export interface WidgetAgreementMetadata {
  staticFileId: string;
  name: string;
  size: string;
  mimeType: string;
}

export interface UpdatePracticeWidgetGeneralSettingsArgs {
  practiceNameInWidget: string;
  practicePhoneInWidget: string;
  practiceEmailInWidget: string;
  practiceBackgroundColor: string;
  practiceDoctorsBackgroundColor: string;
  practiceDoctorsBordersColor: string;
  practiceDoctorsWidgetHeight: string;
  practiceDoctorsWidgetWidth: string;
  practiceBranchesVisibilityInWidget: PracticeBranchVisibilityOnWidget[];
}

export interface PracticeWidgetColorSettingsModel {
  backgroundColor: string;
  doctorsBackgroundColor: string;
  doctorsBorderColor: string;
}

export interface UpdatePracticeWidgetBannerArgs {
  showInWidget: boolean;
  title: string;
  description: string;
  buttonTitle: string;
  buttonUrl: string;
}

export interface PracticeUserArgs {
  lastName: string;
  firstName: string;
  middleName: string;
  position: string;
  role: UserRole;
  email: string;
  snils: string;
  phone: string;
  hasAccessToBranches: string[];
  hasAccessToPages: string[];
}

export interface UpdatePracticeUserArgs {
  userId: string;
  enabled: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  position: string;
  snils: string;
  role: string;
  phone: string;
  hasAccessToBranches: string[];
  hasAccessToPages: string[];
}

export interface DoctorGeneralInfoArgs {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDay: string;
  gender: MilaGender;
  category: MilaDoctorCategory;
  jobTitle: string;
  experience: string;
  aboutDoctor: string;
  academicDegreeId: string;
  specializations: PracticeDoctorSpecialization;
}

export interface CreateDoctorEducationArgs {
  institution: string;
  educationType: MilaDoctorEducationType;
  speciality: string;
  educationEndDate: string;
}

export interface UpdateDoctorEducationArgs {
  institution: string;
  educationType: MilaDoctorEducationType;
  speciality: string;
  educationEndDate: string;
}

export interface PracticeDoctorVisibilityArgs {
  showInMilaServices: boolean;
}

export interface UpdateSpecialityMarketingNameArgs {
  practiceId: string;
  specialityFederalId: string;
  newName: string;
}

export interface CreateMedOrganizationSpecialtyServiceArgs extends NewPracticeService {
  medOrganizationId: string;
}

export interface UpdateMedOrganizationSpecialtyServiceArgs extends NewPracticeService {
  serviceId: string;
  specialityId: string;
  specialityName: string;
  medOrganizationId: string;
}

export interface DeleteMedOrganizationSpecialtyServiceArgs {
  serviceId: string;
  specialityId: string;
  medOrganizationId: string;
}

export interface UpdatePracticeDoctorSpecialtyServiceArgs {
  serveAgeFrom: number;
  serveAgeTo: number;
  showInWidget: boolean;
  practiceServiceId: string;
  practiceSpecializationId: string;
  practiceDoctorId: string;
  medOrganizationId: string;
}

export interface PermissionEntity {
  name: string;
  value: string;
}

export interface StatisticBlockProps {
  title: string;
  icon: string;
  link: string;
  data: StatisticDataEntry[];
  minimized?: boolean;
}

export interface StatisticDataEntry {
  label: string;
  value: number;
  highlight?: boolean;
}
export interface IMarketingCardProps {
  title: string;
  description: string;
  imagePath?: string;
  linkPath: string;
  buttonIcon?: React.ReactElement;
  buttonText?: string;
  buttonIcon2?: React.ReactElement;
  buttonText2?: string;
  isPrimaryButtonDisabled?: boolean;
}

export interface Promotion {
  banner: React.ReactElement;
  updatedAt: React.ReactElement;
  header: React.ReactElement;
  timePeriod: React.ReactElement;
  branchesCount: number;
  doctorsCount: number;
  viewsCount: number;
  visibility: React.ReactElement;
}

export interface PromotionListColumns {
  banner: React.ReactElement;
  updatedAt: React.ReactElement;
  header: { header: string; promotionId: string };
  timePeriod: React.ReactElement;
  branchesCount: number;
  doctorsCount: number;
  viewsCount: number;
  visibility: React.ReactElement;
}

export interface PromotionArgs {
  id: string;
  title: string;
  titleDescription: string;
  buttonTitle: string;
  buttonUrl: string;
  description: string;
  backgroundImageId: string;
  isTimeLimited: boolean;
  begins: Dayjs | null;
  ends: Dayjs | null;
  participatingBranches: string[];
  participatingBranchesDisplayType: number;
  showForPatients?: boolean;
  showInPatientPersonalAccount?: boolean;
  participatingPracticeDoctorIds: string[];
}

export interface UpdatePromotionArgs {
  title: string;
  titleDescription: string;
  buttonTitle: string;
  buttonUrl: string;
  description: string;
  isTimeLimited: boolean;
  begins: Dayjs | null;
  ends: Dayjs | null;
  participatingBranches: string[];
  participatingBranchesDisplayType: number;
  showForPatients: boolean;
  promotionId: string;
  participatingPracticeDoctorIds: string[];
}

export type NewPromotionArgs = Omit<PromotionArgs, 'id' | 'backgroundImageId'>;

export interface PromotionListItem {
  backgroundImageStaticFileId: number;
  updateDate: string;
  title: string;
  startDate: string;
  endDate: string;
  branchesCount: number;
  doctorsCount: number;
  viewsCount: number;
  isVisibleForPatients: boolean;
  isTimeLimited: boolean;
  promotionId: string;
}
export interface PromotionsList {
  data: PromotionListItem[];
  total: number;
}

export interface DashboardStatisticsArgs {
  asOfDate: string;
  branchId?: string;
}

export interface PatientsStatisticData {
  newPatients: number;
  totalPatients: number;
}

export interface AppointmentsStatisticData {
  newAppointment: number;
  cancelledCount: number;
  totalAppointment: number;
}

export interface TeleHealthStatisticData {
  newTelehealthAppointments: number;
  cancelledCount: number;
  completedCount: number;
  totalTelehealthAppointments: number;
}

export interface WaitingListStatisticData {
  newInWaitingList: number;
  totalInWaitingList: number;
  confirmed: number;
  unconfirmed: number;
}

export interface CallMeBackStatisticData {
  newCallMeBacks: number;
  totalCallMeBacks: number;
  confirmed: number;
  unconfirmed: number;
}

export interface DoctorReviewsStatisticData {
  newReviews: number;
  averageScore: number;
  negative: number;
  comments: number;
}

export interface TabItem {
  key: string;
  label: string;
}

export interface PromotionFormState {
  header: string;
  titleDescription: string;
  buttonName: string;
  buttonUrl: string;
  detailedConditions: string;
  showInPatientAccount: boolean;
  branchesStateList: string[];
}

export interface PromotionSpecializationData {
  specializationId: string;
  specializationName: string;
}

export interface PromotionDoctors {
  practiceDoctorId: string;
  photoStaticFileId: string;
  fullName: string;
  specializations: string[];
  branches: string[];
}

export interface WidgetDoctors {
  idMilaDoctor: string;
  idPhotoFile: string;
  fullName: string;
  specializations: string[];
}

export interface PromotionFormValues {
  header: string;
  titleDescription: string;
  buttonName: string;
  buttonUrl: string;
  detailedConditions: string;
  showForPatients: boolean;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export interface PromotionDoctorsLstArgs {
  specializationId: string;
  branches: string[];
}

export interface AccessToDoctorPortalArgs {
  practiceId?: string;
  practiceDoctorId?: string;
  setAccess: boolean;
}

export interface ChangeAccessToDoctorPortalArgs {
  practiceId?: string;
  practiceDoctorId?: string;
  request: {
    password?: string | undefined;
    hasAccessToDoctorPortal: boolean;
    hasAccessToDoctorPortalWithDoctor: boolean;
  }
}

export interface ResendVerificationEmailArgs {
  practiceId?: string;
  practiceDoctorId?: string;
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface ServiceInfo {
  slideshowImageStaticFileIds: string[];
  aboutServiceDeveloper: string;
  aboutServicePrice: string;
  aboutServiceDescription: string;
  limitationsDescription: string;
  setupDescription: string;
  supportDescription: string;
  faq: {
    question: string;
    answer: string;
  }[];
}

export interface TmkUserInfo {
  sessionId: string;
  userId: string;
  firstRoomId: string;
}

export interface AmoCrmAuthUrl {
  url: string;
}

export enum NotificationType {
  Appointment,
  Tmk,
  AtHome,
  Callmeback,
  WaitingList,
  AppointmentCancelled,
  GlobalTechWorks,
  GlobalNewFeature,
}

export interface DictionaryItem {
  code: string;
  name: string;
}

export interface DictionarySubwayLineItem {
  id: string;
  name: string;
  hex_color?: string;
  stations: DictionarySubwayStationItem[];
  icon?: React.JSX.Element;
}

export type DictionarySubwayStationItem = Omit<DictionarySubwayLineItem, 'stations'>;

export interface DictionarySubwayItem {
  id: string;
  name: string;
  hex_color?: string;
  lines: DictionarySubwayLineItem[];
  icon?: React.JSX.Element;
}

export interface PromotionTabDataType {
  [key: string]: {
    aboutComponent: React.ReactElement;
    pageTitle: string;
  };
}

export interface DataResponse<T> {
  data: T[];
  total: number;
}

export interface Notification {
  content: NotificationData;
  idNotification: string;
  isViewed: boolean;
  type: number;
}

export interface NotificationData {
  description?: string;
  title?: string;
  branchDoctorFullName?: string;
  branchDoctorId?: string;
  branchId?: string;
  branchShortName?: string;
  idWaitingListItem?: string;
  notificationType?: number;
  patientDateOfBirth?: string;
  patientFullName?: string;
  patientId?: string;
  patientPhone?: string;
  serviceCost?: string;
  serviceName?: string;
  name?: string;
  phone?: string;
  whenToCall?: string;
  notificationDateTimeOffset?: string;
  appointmentDateTimeFrom?: string;
  appointmentDateTimeTo?: string;
}

export interface PracticePosition {
  specialityId: string;
  specialityPositionId: string;
  positionFerId: string;
  positionName: string;
}

export interface PracticeSpecialityPosition {
  medOrganizationId: string;
  specialityFerId: string;
  specialityName: string;
  positions: PracticePosition[];
}

export interface PracticePositionPostArgs {
  branchId: string;
  specialityFerId: string;
  positionCode: string;
  positionName: string;
}

export interface PracticePositionPutArgs {
  branchId: string;
  specialityId: string;
  specialityPositionId: string;
  positionCode: string;
  positionName: string;
}

export interface PracticePositionDeleteArgs {
  branchId: string;
  specialityId: string;
  specialityPositionId: string;
}

export type DateAndSortArgs = DashboardStatisticsArgs & RecordsArguments;

export interface ServiceRequestArgs {
  serviceKey: string;
  idMedOrganization: string;
}

export enum MilaService {
  OnSite = 0,
  AtHome = 1,
  Online = 2,
  ChatWithClinic = 3,
  Discounts = 4,
  UploadDocuments = 5,
  SharedMedcard = 6,
  AddClinic = 7,
  AddProfile = 8
}

export interface WhiteLabelData {
  domain: string;
  isServiceEnabled: boolean;
  idLogoFile: string;
  idMedCardAgreementFile: string;
  idNotificationAgreementFile: string;
  idPersonalDataAgreementFile: string;
  idOfferAgreementFile: string;

  idIconFile: string;
  idFaviconFile: string;
  idPwaIconFile: string;
  headersColor: string;
  displayName: string;
  copyrightTitle: string;
  copyrightDescription: string;
  linksColor: string;

  medCardUpdatesColor: {
    textColor: string;
    backgroundColor: string;
  };
  nearestAppointmentColor: {
    textColor: string;
    backgroundColor: string;
  };
  mainButtonColor: {
    textColor: string;
    backgroundColor: string;
  };
  services: MilaService[];
  supportEmails: string[];
  privacyPolicy: string;
  paymentRules: string;
  organizationInfo: string;
}

export interface WhiteLabelUpdateData {
  headersColor: string;
  linksColor: string;
  displayName: string;
  copyrightTitle: string;
  copyrightDescription: string;
  privacyPolicy: string;
  paymentRules: string;
  organizationInfo: string;
  mainButtonColor: {
    textColor: string;
    backgroundColor: string;
  };
  nearestAppointmentColor: {
    textColor: string;
    backgroundColor: string;
  };
  medCardUpdateColor: {
    textColor: string;
    backgroundColor: string;
  };
  supportEmails: string[];
}

export interface RunSzpvSyncForMedOraganization {
  moId: string;
}

export enum MilaRole {
  BranchAdmin = 1,
  Auditor = 4,
  Sponsor = 5
}