import { Layout, Typography } from 'antd';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

export interface ServiceComponentProps {
  aboutServiceTab: ReactNode;
  name: string;
  noSideBar?: boolean;
}

export const ServiceComponent = ({ aboutServiceTab, name, noSideBar = false }: ServiceComponentProps) => {
  return (
    <Layout className={styles.ServiceComponentWrapper}>
      <Typography className={`${styles.ServiceName} ${noSideBar ? styles.ServiceNameWithoutSideBar : ''}`}>{name}</Typography>
      {aboutServiceTab}
    </Layout>
  );
};
