export enum Statuses {
  'Не подтверждено',
  'Подтверждено',
}

export enum UserRole {
  'Администратор сети',
  'Администратор филиала',
  'Администратор самостоятельной МедОрганизации',
  'Доктор',
  'Аудитор',
  'Спонсор'
}

export enum PaymentStatusType {
  CreatedAndNotPaid = 0,

  WithdrawBySystem = 1,
  WithdrawByClinic = 11,
  WithdrawByClinicWithoutReport = 13,
  WithdrawByClinicAndReportIsReady = 14,
  WithdrawByPatientInOrderOfCancellingAnAppointment = 12,

  Canceled = 2,

  RefundedBySystem = 3,
  RefundedByPatient = 31,
  RefundedByClinic = 32,

  MoneyReserved = 5,

  PaymentSystemError = 6,
}

export enum ServiceTabKey {
  Widget = 'widget',
  Patient = 'patient',
  WhiteLabel = 'whiteLabel',
  Tmk = 'tmk',
  Schedule = 'schedule',
}

export enum AgreementType {
  PersonalData = 1,
  MedCard = 2,
  Notification = 3,
  Offer = 4
}
