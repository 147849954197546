import { Avatar, Breadcrumb, Button, Col, Layout, Menu, MenuProps, Typography } from 'antd';
import { BurgerMenu } from './Inner/BurgerMenu';
import { ReactComponent as CompanyMenu } from '../../common/Icons/CompanyMenu.svg';
import { ReactComponent as CompanyMenuActive } from '../../common/Icons/CompanyMenuActive.svg';
import { ReactComponent as HomeIcon } from '../../common/Icons/HomeIcon.svg';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MarketingMenu } from '../../common/Icons/MarketingMenu.svg';
import { ReactComponent as MarketingMenuActive } from '../../common/Icons/MarketingMenuActive.svg';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { ReactComponent as RecordsMenu } from '../../common/Icons/RecordsMenu.svg';
import { ReactComponent as RecordsMenuActive } from '../../common/Icons/RecordsMenuActive.svg';
import { ReactComponent as SeparatorBreadcrumbIcon } from '../../common/Icons/SeparatorBreadcrumbIcon.svg';
import { ReactComponent as ServicesMenu } from '../../common/Icons/ServicesMenu.svg';
import { ReactComponent as ServicesMenuActive } from '../../common/Icons/ServicesMenuActive.svg';
import { ReactComponent as StatisticMenu } from '../../common/Icons/StatisticsMenu.svg';
import { ReactComponent as StatisticMenuActive } from '../../common/Icons/StatisticsMenuActive.svg';
import { ReactComponent as SidebarArrowIcon } from '../../common/Icons/SidebarArrowIcon.svg';
import { ReactComponent as SidebarOpenArrowIcon } from '../../common/Icons/SidebarOpenArrowIcon.svg';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';

import { apiSlice, useGetPracticeBranchesQuery } from '../../app/services/api';
import { getNavUserName, getPreparedBranches } from 'src/common/utils/utils';
import { resetApp, setBranch, setServiceBranch } from '../../app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useAuth } from 'react-oidc-context';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import {Branch, MilaRole} from 'src/common/types';

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];
const amoCrmLink: string = window.__RUNTIME_CONFIG__.REACT_APP_AMO_CRM_LK_URL;

const menuItems = {
  1: 'services',
  2: 'company',
  3: 'records/appointments',
  4: 'marketing',
  5: 'statistic',
  6: 'patients',
} as { [key: string]: string };

const breadcrumbsItems = {
  access: 'Доступ в кабинет врача',
  company: 'Компания',
  records: 'Записи в клинику',
  marketing: 'Маркетинг',
  statistic: 'Статистика',
  //patients: 'Пациенты',
  services: 'Сервисы',
  branches: 'Филиалы',
  doctors: 'Врачи и специалисты',
  general: 'Профиль врача',
  amenities: 'Услуги',
  specializations: 'Специализации',
  agreements: 'Соглашения и оферты',
  // accesses: 'Доступ',
  // userAccesses: 'Добавить доступ',
  cabinets: 'Кабинеты',
  amenitiesDoctor: 'Услуги врача',
  widgetSettings: 'Виджет онлайн-записи / Настройки виджета',
  changePassword: 'Сменить пароль',
  promotionsList: 'Акции клиники',
  widgetPromoBlock: 'Промоблок в виджете записи',
  yandexMaps: 'Яндекс.Карты',
  promocodesGenerator: 'Генератор промокодов',
  yandexDoctors: 'Врачи в Яндекс.Поиске',
  messengers: 'Рассылки в мессенджеры',
  promotionForm: 'Добавить / Изменить акцию',
  profile: 'Профиль пользователя',
  about: 'О сервисе',
  settings: 'Настройки',
  widget: 'Виджет онлайн-записи',
  notifications: 'Уведомления',
  patient: 'Профиль клиники',
  tmk: 'Телемедицина',
  research: 'Клинические исследования',
  chats: 'Чаты с пациентами',
  whiteLabel: 'White Label',
  sync: 'Обмен с МИС',
  'tm.schedule': 'Расписание',
} as { [key: string]: string };

const renderUserInfo = (collapsedUserMenu: boolean, userName: string, userSurname: string) => {
  return (
    <div>
      <div className={styles.userNameContainer}>
        <Typography.Text>
          {userSurname?.length > 0
            ? getNavUserName(`${userSurname} ${userName?.charAt(0).toLocaleUpperCase()}.`)
            : getNavUserName(`${userName}`)}
        </Typography.Text>
        {collapsedUserMenu && <Typography.Text className={styles.userDescription}>Администратор сети</Typography.Text>}
      </div>
      <div>
        <Avatar shape={'circle'} src={''} size={44} className={styles.userAvatar}>
          {`${userSurname?.charAt(0).toLocaleUpperCase()}${userName?.charAt(0).toLocaleUpperCase()}`}
        </Avatar>
      </div>
    </div>
  );
};

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const authService = oidcAuthService;
  const loggedUserName = useMemo(
    () => auth.user?.profile.given_name || auth.user?.profile.preferred_username || '',
    [auth.user?.profile.given_name],
  );

  const loggedUserSurname = useMemo(() => auth.user?.profile.family_name || '', [auth.user?.profile.family_name]);
  const { pathname } = location;
  const dispatch = useAppDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedUserMenu, setCollapsedUserMenu] = useState(false);

  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);

  const [branchesHasScheduleWidget, setBranchesHasScheduleWidget] = useState<boolean>(false);
  const [branchesHasTmData, setBranchesHasTmData] = useState<boolean>(false);
  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const availableBranches = useAppSelector((state) => state.app.availableBranches);
  const prepareBranches = (branchesData: Branch[] | undefined) =>
    branchesData ? getPreparedBranches(branchesList || [], availableBranches ?? []) : [];

  const breadcrumbsPath = useMemo(() => pathname.split('/').filter((item) => item), [pathname]);

  const preparedBreadcrumbs = useMemo(
    () => breadcrumbsPath.map((item: string) => breadcrumbsItems[item]).filter((item: string) => item !== undefined),
    [breadcrumbsPath],
  );

  const role = useAppSelector((state) => state.app.role);

  useEffect(() => {
    const preparedBranches = prepareBranches(branchesList);

    branchesList?.forEach((branch) => {
      if (
        branch.isScheduleServiceEnabled &&
        preparedBranches.findIndex((item) => item.value === branch.branchId) !== -1
      ) {
        setBranchesHasScheduleWidget(true);
      }
      if (branch.useTmData && preparedBranches.findIndex((item) => item.value === branch.branchId) !== -1) {
        setBranchesHasTmData(true);
      }
    });
  }, [branchesList]);

  const handleLogout = async () => {
    dispatch(resetApp());
    dispatch(apiSlice.util.resetApiState());

    authService.logout();
  };

  const handleChangePassword = async () => {
    window.location.href = await authService.changeUserPasswordUrl();
  };

  const handleRedirectToProfile = () => {
    navigate(`/company/accesses/userAccesses/profile`);
  };

  const handleRedirectToNetworkSelection = () => {
    dispatch(setServiceBranch(''));
    dispatch(setBranch(''));
    navigate('/networkSelection');
  };

  const handleOpenBurger = () => setOpenBurgerMenu((prev) => !prev);

  function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const selectedItem = {
    name: location.pathname.split('/')[1] === '' ? 'services' : location.pathname.split('/')[1],
    index:
      location.pathname.split('/')[1] === ''
        ? '1'
        : Object.entries(menuItems).find((item) => item[1].includes(location.pathname.split('/')[1]))?.[0] || '1',
  };

  const items: MenuItem[] = [
    getItem('Сервисы', '1', selectedItem.name === 'services' ? <ServicesMenuActive /> : <ServicesMenu />),
    ![MilaRole.Auditor, MilaRole.Sponsor].includes(role as MilaRole) ? getItem('Компания', '2', selectedItem.name === 'company' ? <CompanyMenuActive /> : <CompanyMenu />) : null,
    ![MilaRole.Auditor, MilaRole.Sponsor].includes(role as MilaRole) ? getItem('Записи', '3', selectedItem.name === 'records' ? <RecordsMenuActive /> : <RecordsMenu />) : null,
    ![MilaRole.Auditor, MilaRole.Sponsor].includes(role as MilaRole) ? getItem('Маркетинг', '4', selectedItem.name === 'marketing' ? <MarketingMenuActive /> : <MarketingMenu />) : null,
    ![MilaRole.Auditor, MilaRole.Sponsor].includes(role as MilaRole) ? getItem('Статистика', '5', selectedItem.name === 'statistic' ? <StatisticMenuActive /> : <StatisticMenu />) : null,
    //getItem('Пациенты', '4', selectedItem.name === 'patients' ? <PatientsMenuActive /> : <PatientsMenu />),
  ].filter(x => x !== null);

  const itemsUserMenu: MenuItem[] = [
    {
      key: 'userName',
      icon: renderUserInfo(collapsedUserMenu, loggedUserName, loggedUserSurname),
      children: [
        {
          label: 'Профиль пользователя',
          key: '1',
          onClick: () => handleRedirectToProfile(),
        },
        {
          label: 'Сменить пароль',
          key: '2',
          onClick: () => handleChangePassword(),
        },
        {
          label: 'Сменить организацию',
          key: '3',
          onClick: () => handleRedirectToNetworkSelection(),
        },
        {
          label: <span style={{ color: '#FA6060' }}>Выйти</span>,
          key: '4',
          onClick: () => handleLogout(),
        },
      ],
    },
  ];

  const onMenuItemClick = ({ key }: { key: string }) => {
    navigate(`/${menuItems[key]}`);
  };

  const breadcrumbsItemsList = [
    ...[],
    {
      title: (
        <Link to="/">
          <HomeIcon />
        </Link>
      ),
    },
    ...preparedBreadcrumbs.map((path: string, index: number) => {
      const routeTo = `/${breadcrumbsPath.slice(0, index + 1).join('/')}`;
      const isLastItem = preparedBreadcrumbs.length - 1 === index;
      return {
        title: (
          <Typography.Text className={isLastItem ? styles.activeBreadcrumb : styles.inactiveBreadcrumb}>
            {isLastItem ? (
              <Typography.Text>{path}</Typography.Text>
            ) : (
              <Link to={routeTo === '/marketing/promotions' ? '/marketing/promotions/promotionsList' : routeTo}>
                {path}
              </Link>
            )}
          </Typography.Text>
        ),
      };
    }),
  ];

  return (
    <Layout className={styles.navBarContainer}>
      <Header className={styles.headerContainer}>
        <Link className={styles.logo} to={'/'}>
          <MilaIcon />
        </Link>
        <Col className={`${styles.burgerContainer} ${openBurgerMenu ? styles.open : ''} `}>
          <Col className={styles.linksContainer}>
            <Typography.Link href="https://support.n3health.ru" target="_blank" className={styles.LabelsWrapper}>
              Техподдержка
            </Typography.Link>
            <Typography.Link
              href="https://mila.online/manuals_partner"
              target="_blank"
              className={styles.LabelsWrapper}
            >
              Инструкции
            </Typography.Link>
            {![MilaRole.Auditor, MilaRole.Sponsor].includes(role as MilaRole) && <Link to={'notifications'} className={styles.LabelsWrapper}>
              <Typography className={pathname === '/notifications' ? styles.activeNavBarLink : ''}>
                Уведомления
              </Typography>
            </Link>}
            {/*<Link to={'chats'} className={styles.LabelsWrapper}>*/}
            {/*  <Typography className={pathname === '/chats' ? styles.activeNavBarLink : ''}>*/}
            {/*    Чаты с пациентами*/}
            {/*  </Typography>*/}
            {/*</Link>*/}
            {branchesHasScheduleWidget && (
              <Link to={'tm.schedule'} className={styles.LabelsWrapper}>
                <Typography className={pathname === '/tm.schedule' ? styles.activeNavBarLink : ''}>
                  Расписание
                </Typography>
              </Link>
            )}
            {branchesHasTmData && (
              <Link to={'sync'} className={styles.LabelsWrapper}>
                <Typography className={pathname === '/sync' ? styles.activeNavBarLink : ''}>Обмен с МИС</Typography>
              </Link>
            )}
          </Col>
          <Col className={styles.userInfoContainer}>
            <Button
              className={styles.goToN3Btn}
              onClick={() => {
                window.open(amoCrmLink, '_blank');
              }}
            >
              Личный кабинет N3.Health
            </Button>
            <Menu
              style={{ width: 256 }}
              items={itemsUserMenu}
              triggerSubMenuAction={'click'}
              mode="horizontal"
              onOpenChange={() => setCollapsedUserMenu(!collapsedUserMenu)}
            />
          </Col>
        </Col>
        <BurgerMenu open={openBurgerMenu} onClick={handleOpenBurger} />
      </Header>

      <Col className={styles.breadcrambsWrapper}>
        <Breadcrumb
          style={{ padding: '0 50px' }}
          items={breadcrumbsItemsList}
          separator={<SeparatorBreadcrumbIcon className={styles.separatorIcon} />}
        />
      </Col>
      <Col>
        <Layout>
          {!location.pathname.includes('tm.schedule') && !location.pathname.includes('research/settings') && (
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              className={`${styles.siderWrapper} ${[MilaRole.Auditor, MilaRole.Sponsor].includes(role as MilaRole) ? styles.sidebarWrapperSmall : ''}`}
              width="270"
              collapsedWidth="120"
              trigger={
                collapsed ? (
                  <div className={styles.triggerCollapsedButton}>
                    <SidebarOpenArrowIcon />
                  </div>
                ) : (
                  <div className={styles.triggerButton}>
                    <SidebarArrowIcon />
                    <span>Свернуть</span>
                  </div>
                )
              }
            >
              <Menu
                defaultSelectedKeys={[selectedItem.index]}
                mode="inline"
                items={items}
                className={styles.siderMenu}
                onClick={onMenuItemClick}
              />
            </Sider>
          )}
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Col>
    </Layout>
  );
}

export default NavBar;
