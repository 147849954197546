// Couldn't figure out what types to use here
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

export const useDebouncedCall = (query: any, time: number) => {
  let timeoutId: any;
  function wrapper(...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      query(...args);
    }, time);
  }
  return wrapper;
};

export const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
