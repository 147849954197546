import { AccessesStub } from 'src/common/components/AccessesStub/AccessesStub';
import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, Select, Tooltip, Typography, notification } from 'antd';
import { ReactComponent as DownCaretIcon } from '../../../../common/Icons/DownCaretIcon.svg';
import { ErrorPage } from '../../../../common/components/ErrorPage/ErrorPage';
import { PhoneInput } from '../../../../common/components/PhoneInput/PhoneInput';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/QuestionMarkIcon.svg';
import { ReactComponent as RemoveIcon } from '../../../../common/Icons/RemoveIcon.svg';
import { UserRole } from '../../../../common/types/enums';
import { getPageVisibility } from 'src/common/utils/utils';
import { oidcAuthService } from '../../../../app/services/auth/oidcAuthService';
import {
  useAddNewUserAccessMutation,
  useGetBranchUserByIdQuery,
  useGetPracticeBranchesQuery,
  useUpdateUserAccessMutation,
} from '../../../../app/services/api';
import { useAppSelector } from 'src/app/hooks';
import { useAuth } from 'react-oidc-context';
import { useForm } from 'antd/lib/form/Form';
import { useGetAccessToPagesQuery } from '../../../../app/services/apiAuth';
import { useNavigate, useParams } from 'react-router-dom';
import FloatLabelInput from '../../../../common/FloatLabelInput/FloatLabelInput';
import InputMask from 'react-input-mask';
import NavSubMenu from '../../NavSubMenu';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import StatusWithLabel from '../StatusWithLabel';
import styles from './styles.module.scss';

type BranchEntity = {
  branchId: string;
  shortName: string;
};

export interface UserAccessPageProps {
  isNewUserMode?: boolean;
}

function UserAccessPage({ isNewUserMode }: UserAccessPageProps) {
  const [form] = useForm<any>();
  const authService = oidcAuthService;
  const auth = useAuth();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [isAccessEnabled, setUserAccess] = useState(true);
  const [statusValue, setUserStatusValue] = useState('');
  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [isUserProfilePage, setUserProfilePage] = useState(userId === 'profile');
  
  useEffect(() => {
    setUserProfilePage(userId === 'profile');
  }, [userId]);

  useEffect(() => {
    if (permissions.length) setIsPageVisible(getPageVisibility(permissions, 'can-access-page-accesses'));
  }, [permissions.length]);

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const {
    data: userData,
    error: userDataError,
    refetch: refetchUserData,
  } = useGetBranchUserByIdQuery(userId!, { skip: isNewUserMode && !userId });
  const [addNewUserAccess, newUserRequest] = useAddNewUserAccessMutation();
  const [updateUserAccess, updateUserRequest] = useUpdateUserAccessMutation();
  const { data: pagesAccessData } = useGetAccessToPagesQuery('');
  const loggedUserId = auth.user?.profile.milaId || '';

  useEffect(() => {
    if (!isNewUserMode) refetchUserData();
  }, [isUserProfilePage, isNewUserMode]);

  useEffect(() => {
    if (userDataError) navigate('/services');
  }, [auth, userDataError, isUserProfilePage]);

  const [isSubmitDisabled, setSubmitBtnDisabled] = useState(true);

  const isOwnProfile = useMemo(() => userData?.isSelf, [userData?.isSelf]);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'setField':
        form.setFieldValue([action.field], action.value);
        return { ...state, [action.field]: action.value };
      case 'setFewFields':
        form.setFieldsValue({ ...form.getFieldsValue(), ...action.value });
        return { ...state, ...action.value };
      default:
        return { ...state };
    }
  };

  const handleChangeInput = (e: any) =>
    dispatch({ type: 'setField', field: e.target.name, value: e.target.value, pos: e.target.selectionStart });

  const [fieldValues, dispatch] = useReducer(reducer, {
    surname: '',
    firstname: '',
    middlename: '',
    position: '',
    snils: '',
    role: '',
    phone: '',
    email: '',
    branchesStateList: [],
    pagesAccessStateList: [],
  });

  const setDefaultValues = () => {
    dispatch({
      type: 'setFewFields',
      value: {
        surname: userData?.lastName,
        firstname: userData?.firstName,
        middlename: userData?.middleName,
        position: userData?.position,
        snils: userData?.snils && userData?.snils?.length > 10 ?
            `${userData?.snils.substring(0, 3)}-${userData?.snils.substring(3, 6)}-${userData?.snils.substring(6, 9)}-${userData?.snils.substring(9)}`
        : userData?.snils,
        role: userData?.role,
        phone: userData?.phone,
        email: userData?.email,
        branchesStateList: userData?.hasAccessToBranches || [],
        pagesAccessStateList: userData?.hasAccessToPages || [],
      },
    });
    setUserAccess(userData?.status !== 'Доступ заблокирован');
    if (userData?.status) {
      setUserStatusValue(userData.status);
    }
  };

  useEffect(() => {
    if (userData) {
      setDefaultValues();
    }
  }, [userData]);

  const onFormValuesChange = () => {
    setSubmitBtnDisabled(false);
  };

  const handleSubmitForm = (v: any) => {
    isNewUserMode
      ? addNewUserAccess({
          lastName: v.surname,
          firstName: v.firstname,
          middleName: v.middlename,
          position: v.position,
          snils: v.snils,
          role: v.role,
          phone: v.phone,
          email: v.email,
          hasAccessToBranches: fieldValues.branchesStateList,
          hasAccessToPages: fieldValues.pagesAccessStateList,
        })
          .unwrap()
          .then(() => {
            notification.success({ message: 'Новый пользователь успешно создан' });
          })
          .catch(({ data: { errors } }) => {
            Object.values(errors).map((error) =>
              notification.error({ message: Array.isArray(error) ? error.join(' ') : String(error) }),
            );
          })
      : updateUserAccess({
          lastName: v.surname,
          firstName: v.firstname,
          middleName: v.middlename,
          position: v.position,
          snils: v.snils,
          role: v.role,
          phone: v.phone,
          // email: v.email,
          hasAccessToBranches: fieldValues.branchesStateList,
          hasAccessToPages: isUserProfilePage ? null : fieldValues.pagesAccessStateList,
          enabled: isAccessEnabled,
          userId: userData?.userId!,
        })
          .unwrap()
          .then(() => {
            refetchUserData();
            notification.success({ message: 'Общие данные пользователя успешно обновлены' });
          })
          .catch((error) => notification.error({ message: error.data.detail }));
  };

  const handleToggleUserAccess = () => {
    if (userData?.status === 'Приглашение отправлено') {
      setUserStatusValue(isAccessEnabled ? 'Доступ заблокирован' : 'Приглашение отправлено');
    } else {
      setUserStatusValue(isAccessEnabled ? 'Доступ заблокирован' : 'Активный');
    }

    setUserAccess(!isAccessEnabled);
  };

  const handleBranchToggle = (branchId: string) => {
    if (fieldValues.branchesStateList?.includes(branchId)) {
      const updatedList = fieldValues.branchesStateList.filter((branchListId: string) => branchListId !== branchId);
      dispatch({ type: 'setField', field: 'branchesStateList', value: updatedList });
    } else {
      const updatedList = [...fieldValues.branchesStateList];
      updatedList.push(branchId);
      dispatch({ type: 'setField', field: 'branchesStateList', value: updatedList });
    }
  };

  const handlePageAccessToggle = (accessId: string) => {
    if (fieldValues.pagesAccessStateList?.includes(accessId)) {
      const updatedList = fieldValues.pagesAccessStateList.filter(
        (accessClaimListId: string) => accessClaimListId !== accessId,
      );
      dispatch({ type: 'setField', field: 'pagesAccessStateList', value: updatedList });
    } else {
      const updatedList = [...fieldValues.pagesAccessStateList];
      updatedList.push(accessId);
      dispatch({ type: 'setField', field: 'pagesAccessStateList', value: updatedList });
    }
  };

  const handlePasswordChangeLink = async () => {
    window.location.href = await authService.changeUserPasswordUrl();
  };

  const isUserFormVisible = (!userDataError || isNewUserMode) && isPageVisible;

  return (
    <>
      {!isUserProfilePage && <NavSubMenu />}
        <div className={styles.pageContainer}>
          {isUserFormVisible ? (
            <>
              <div className={styles.titleUserName}>
                {!isNewUserMode ? (
                  <Typography.Text>{userData?.fullName}</Typography.Text>
                ) : (
                  <Typography.Text>Добавить доступ для сотрудника</Typography.Text>
                )}
              </div>

              <Form form={form} layout="vertical" onFinish={handleSubmitForm} onValuesChange={onFormValuesChange}>
                {(isNewUserMode || (form.getFieldValue('surname'))) && (
                  <>
                    <div className={styles.generalDataContainer}>
                      <Col className={styles.generalDataFormTitle}>
                        <Typography.Text>Общие данные</Typography.Text>
                      </Col>
                      <Row gutter={24} className={styles.inputsRowWrapper}>
                        <Col className={styles.inputsWrapper}>
                          <FloatLabelInput label="Фамилия" value={form.getFieldValue('surname')}>
                            <Form.Item name="surname" className={styles.inputSurname}>
                              <Input
                                name="surname"
                                style={{ height: '56px', minWidth: '418px', resize: 'none' }}
                                onChange={handleChangeInput}
                                maxLength={20}
                              />
                            </Form.Item>
                          </FloatLabelInput>

                          <FloatLabelInput label="Имя" value={form.getFieldValue('firstname')}>
                            <Form.Item name="firstname" className={styles.inputName}>
                              <Input
                                name="firstname"
                                style={{ height: '56px' }}
                                onChange={handleChangeInput}
                                maxLength={20}
                              />
                            </Form.Item>
                          </FloatLabelInput>

                          <FloatLabelInput label="Отчество" value={form.getFieldValue('middlename')}>
                            <Form.Item name="middlename" className={styles.inputName}>
                              <Input
                                name="middlename"
                                style={{ height: '56px' }}
                                onChange={handleChangeInput}
                                maxLength={20}
                              />
                            </Form.Item>
                          </FloatLabelInput>

                          <FloatLabelInput label="Должность" value={form.getFieldValue('position')}>
                            <Form.Item name="position" className={styles.inputName}>
                              <Input
                                name="position"
                                style={{ height: '56px' }}
                                onChange={handleChangeInput}
                                maxLength={20}
                              />
                            </Form.Item>
                          </FloatLabelInput>
                        </Col>

                        <Col className={styles.inputsWrapper}>
                          <FloatLabelInput
                            label="Роль"
                            isDisabled={isUserProfilePage}
                            value={form.getFieldValue('role')}
                            suffixOffset={'12px'}
                            suffixIcon={
                              <Tooltip title={'Текст подсказки'} placement="right">
                                <QuestionMarkIcon />
                              </Tooltip>
                            }
                          >
                            <Form.Item name="role">
                              <Select
                                style={{ height: '56px', width: '417px' }}
                                className={styles.selectorWrapper}
                                onChange={(value) => dispatch({ type: 'setField', field: 'role', value })}
                                disabled={isUserProfilePage}
                                suffixIcon={<DownCaretIcon />}
                                options={Object.entries(UserRole)
                                  .filter(([index]) => UserRole[Number(index)])
                                  .map(([index, role]) => ({
                                    label: role,
                                    value: +index,
                                  }))}
                              />
                            </Form.Item>
                          </FloatLabelInput>

                          <FloatLabelInput
                            label="Email сотрудника (логин)"
                            isDisabled={!isNewUserMode}
                            value={form.getFieldValue('email')}
                            suffixOffset={'12px'}
                            suffixIcon={
                              <Tooltip title={'Текст подсказки'} placement="right">
                                <QuestionMarkIcon />
                              </Tooltip>
                            }
                          >
                            <Form.Item
                              name="email"
                              className={styles.inputPosition}
                              rules={[
                                {
                                  message: 'Пожалуйста введите корректную электронную почту',
                                  pattern:
                                    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                                },
                              ]}
                            >
                              <Input
                                name="email"
                                style={{ height: '56px', paddingRight: '35px' }}
                                onChange={handleChangeInput}
                                maxLength={40}
                                disabled={!isNewUserMode}
                              />
                            </Form.Item>
                          </FloatLabelInput>

                          <FloatLabelInput
                            label="СНИЛС"
                            isDisabled={isUserProfilePage}
                            value={form.getFieldValue('snils')}
                            suffixOffset={'12px'}
                            suffixIcon={
                              <Tooltip title={'Текст подсказки'} placement="right">
                                <QuestionMarkIcon />
                              </Tooltip>
                            }
                          >
                            <Form.Item
                              name="snils"
                              className={styles.inputName}
                              rules={[
                                {
                                  required: true,
                                  message: 'Пожалуйста введите СНИЛС в формате XXX-XXX-XXX-XX!',
                                  pattern: /\d{3}-\d{3}-\d{3}-\d{2}/,
                                },
                              ]}
                            >
                              <InputMask
                                mask="999-999-999-99"
                                value={form.getFieldValue('snils')}
                                className={styles.inputName}
                                disabled={isUserProfilePage}
                                name="snils"
                                style={{ height: '56px' }}
                                onChange={handleChangeInput}
                              >
                                <Input className={styles.inputName} name="snils" />
                              </InputMask>
                            </Form.Item>
                          </FloatLabelInput>

                          <FloatLabelInput label="Телефон" value={form.getFieldValue('phone')}>
                            <Form.Item
                              name="phone"
                              className={styles.inputName}
                              rules={[
                                {
                                  message: 'Пожалуйста введите корректный номер телефона',
                                  pattern: /(\d|\+\d) \(\d{3}\) \d{3}-\d{4}/,
                                },
                              ]}
                            >
                              <PhoneInput
                                value={form.getFieldValue('phone')}
                                className={styles.inputName}
                                name="phone"
                                style={{ height: '56px' }}
                                onChange={handleChangeInput}
                              />
                            </Form.Item>
                          </FloatLabelInput>
                        </Col>
                        {!isNewUserMode && !isOwnProfile && (
                          <Col className={styles.accessStatus}>
                            <Row className={styles.accessStatusColumn}>
                              <StatusWithLabel value={statusValue} />
                              <Popconfirm
                                title={`Вы уверены, что хотите изменить доступ пользователя?`}
                                onConfirm={handleToggleUserAccess}
                                okText="Да"
                                cancelText="Нет"
                              >
                                <Button className={styles.switchAccessButton} icon={<RemoveIcon />}>
                                  {isAccessEnabled ? 'Отключить доступ' : 'Включить доступ'}
                                </Button>
                              </Popconfirm>
                            </Row>
                          </Col>
                        )}
                      </Row>
                      {isUserProfilePage && (
                        <Row className={styles.linkTextWrapper}>
                          <Typography.Link onClick={handlePasswordChangeLink}>Изменить пароль</Typography.Link>
                        </Row>
                      )}
                    </div>

                    <Col className={styles.branchesFormContainer}>
                      <Col className={styles.generalDataFormTitle}>
                        <Typography.Text>Доступ к филиалам</Typography.Text>
                      </Col>
                      <Col className={styles.checkboxesContainer} span={12}>
                        {branchesList?.map((branch: BranchEntity) => {
                          return (
                            <Col span={12} key={branch.branchId}>
                              <Form.Item name={branch.branchId}>
                                <Checkbox
                                  checked={fieldValues.branchesStateList?.includes(branch.branchId)}
                                  disabled={isUserProfilePage}
                                  onChange={(e) => {
                                    handleBranchToggle(e.target.id!);
                                  }}
                                >
                                  {branch.shortName}
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          );
                        })}
                      </Col>
                      {/*{isUserProfilePage && (*/}
                      {/*  <Row className={styles.linkTextWrapper}>*/}
                      {/*    <Typography.Text>*/}
                      {/*      Доступ к филиалам настраивается Администратором сети в разделе*/}
                      {/*    </Typography.Text>*/}
                      {/*    <Typography.Link onClick={() => navigate('/company/accesses')}> "Доступ"</Typography.Link>*/}
                      {/*  </Row>*/}
                      {/*)}*/}
                    </Col>

                    {!isUserProfilePage && (
                      <Col className={styles.userRightsFormContainer}>
                        <Col className={styles.generalDataFormTitle}>
                          <Typography.Text>Права доступа</Typography.Text>
                        </Col>
                        <Row className={styles.checkboxesContainer}>
                          {pagesAccessData?.map((accessEntity: any) => {
                            return (
                              <Col
                                span={12}
                                className={styles.pageAccessGroupWrapper}
                                key={accessEntity.accessGroupName}
                              >
                                <Col className={styles.userRightsSubTitle}>
                                  <Typography.Text>{accessEntity.accessGroupName}</Typography.Text>
                                </Col>
                                {accessEntity.accesses?.map((access: any) => {
                                  return (
                                    <Col span={12} key={access.accessName}>
                                      {
                                        <Form.Item
                                          name={access.accessClaim}
                                          className={`${styles.pageAccessFormItem} ${
                                            !access.accessClaim && styles.hiddenClaim
                                          }`}
                                        >
                                          <Checkbox
                                            checked={fieldValues.pagesAccessStateList?.includes(access.accessClaim)}
                                            onChange={(e) => {
                                              handlePageAccessToggle(e.target.id!);
                                            }}
                                          >
                                            {access.accessName}
                                          </Checkbox>
                                        </Form.Item>
                                      }
                                    </Col>
                                  );
                                })}
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    )}

                    <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
                      <Col>
                        <Button className={styles.buttonCancel} onClick={() => setDefaultValues()}>
                          Отмена
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className={isSubmitDisabled ? styles.buttonSubmitDisabled : styles.buttonSubmit}
                          htmlType="submit"
                          loading={newUserRequest.isLoading || updateUserRequest.isLoading}
                          // disabled={isSubmitDisabled}
                        >
                          Сохранить
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </>
          ) : (
            <AccessesStub
              title="Здесь вы увидите список сотрудников, которым был предоставлен доступ в ЛК вашей организации"
              text="Вы можете самостоятельно добавлять и отключать пользователей ЛК вашей организации, а также настраивать права доступа к разделам.

            Если вы считаете, что данные сотрудников не отображаются по ошибке, пожалуйста, обратитесь в службу поддержки."
            />
          )}
        </div>
    </>
  );
}

export default UserAccessPage;
