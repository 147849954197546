import { BranchFilter } from 'src/common/components/BranchFilter/BranchFilter';
import { CustomDatePicker } from 'src/common/components/CustomDatePicker/CustomDatePicker';
import { Layout, Row } from 'antd';
import { StatisticBlock } from './StatisticBlock/StatisticBlock';
import { StatisticBlockProps } from 'src/common/types';
import { useAuth } from 'react-oidc-context';
import {
  useGetAppointmentsStatisticQuery,
  useGetCallMeBackStatisticQuery,
  useGetDoctorReviewsStatisticQuery,
  useGetPatientsStatisticQuery,
  useGetTeleHealthStatisticQuery,
  useGetWaitingListStatisticQuery,
} from 'src/app/services/api';
import AppointmentsIcon from '../../common/Icons/AppointmentsIcon.svg';
import CallBack from '../../common/Icons/CallBack.svg';
import DoctorsCardIcon from '../../common/Icons/DoctorsCardIcon.svg';
import Headphones from '../../common/Icons/Headphones.svg';
import PatientsIcon from '../../common/Icons/PatientsIcon.svg';
import React, { useCallback, useMemo, useState } from 'react';
import Typography from 'antd/es/typography/Typography';
import WallClock from '../../common/Icons/WallClock.svg';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';

const disabledDate = (current: Dayjs) => {
  return current && current.startOf('d') > dayjs().startOf('d');
};

export const StatisticPage = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [selectedBranchId, setSelectedBranchId] = useState('');

  const { user } = useAuth();

  const statisticsArgs = useMemo(
    () => ({
      asOfDate: currentDate.utc().startOf('day').toISOString(),
      branchId: selectedBranchId,
    }),
    [currentDate, selectedBranchId],
  );

  const { data: patientsStatistic } = useGetPatientsStatisticQuery(statisticsArgs);
  const { data: appointmentsStatistic } = useGetAppointmentsStatisticQuery(statisticsArgs);
  const { data: teleHealthStatistic } = useGetTeleHealthStatisticQuery(statisticsArgs);
  const { data: waitingListStatistic } = useGetWaitingListStatisticQuery(statisticsArgs);
  const { data: callMeBackStatistic } = useGetCallMeBackStatisticQuery(statisticsArgs.asOfDate);
  const { data: doctorReviewsStatistic } = useGetDoctorReviewsStatisticQuery(statisticsArgs);

  const handleDateChange = (selectedDate: Dayjs | null) => {
    if (selectedDate) {
      setCurrentDate(selectedDate);
    }
  };

  const handleBranchSelect = useCallback((value: string) => {
    setSelectedBranchId(value);
  }, []);

  const items: StatisticBlockProps[] = useMemo(
    () => [
      {
        title: 'Всего пациентов в записях через Mila',
        icon: PatientsIcon,
        data: [
          {
            label: 'Новых за день:',
            value: patientsStatistic?.newPatients ?? 0,
          },
          {
            label: 'Всего в базе:',
            value: patientsStatistic?.totalPatients ?? 0,
          },
        ],
        link: '/patients',
      },
      {
        title: 'Записи к врачу в клинику',
        icon: AppointmentsIcon,
        data: [
          {
            label: 'Записей за день:',
            value: appointmentsStatistic?.newAppointment ?? 0,
          },
          {
            label: 'Отмен за день:',
            value: appointmentsStatistic?.cancelledCount ?? 0,
            highlight: true,
          },
          {
            label: 'Всего записей:',
            value: appointmentsStatistic?.totalAppointment ?? 0,
          },
        ],
        link: '/records/appointments',
        minimized: true,
      },

      {
        title: 'Онлайн-консультации',
        icon: Headphones,
        data: [
          {
            label: 'Записей за день:',
            value: teleHealthStatistic?.newTelehealthAppointments ?? 0,
          },
          {
            label: 'Проведено за день:',
            value: teleHealthStatistic?.completedCount ?? 0,
          },
          {
            label: 'Отмен за день:',
            value: teleHealthStatistic?.cancelledCount ?? 0,
            highlight: true,
          },
          {
            label: 'Всего записей:',
            value: teleHealthStatistic?.totalTelehealthAppointments ?? 0,
          },
        ],
        link: '/records/tmk',
      },

      {
        title: 'Записи в лист ожидания',
        icon: WallClock,
        data: [
          {
            label: 'Записей за день:',
            value: waitingListStatistic?.newInWaitingList ?? 0,
          },
          {
            label: 'Подтверждено всего:',
            value: waitingListStatistic?.confirmed ?? 0,
          },
          {
            label: 'Не подтверждено всего:',
            value: waitingListStatistic?.unconfirmed ?? 0,
            highlight: true,
          },
          {
            label: 'Всего записей:',
            value: waitingListStatistic?.totalInWaitingList ?? 0,
          },
        ],
        link: '/records/waitingList',
      },

      {
        title: 'Запросы обратного звонка',
        icon: CallBack,
        data: [
          {
            label: 'Запросов за день:',
            value: callMeBackStatistic?.newCallMeBacks ?? 0,
          },
          {
            label: 'Подтверждено всего:',
            value: callMeBackStatistic?.confirmed ?? 0,
          },
          {
            label: 'Не подтверждено всего:',
            value: callMeBackStatistic?.unconfirmed ?? 0,
            highlight: true,
          },
          {
            label: 'Всего записей:',
            value: callMeBackStatistic?.totalCallMeBacks ?? 0,
          },
        ],
        link: '/records/callback',
      },

      {
        title: 'Отзывы о врачах в записях',
        icon: DoctorsCardIcon,
        data: [
          {
            label: 'Отзывов за день:',
            value: doctorReviewsStatistic?.newReviews ?? 0,
          },
          {
            label: 'Средний балл по всем:',
            value: doctorReviewsStatistic?.averageScore ?? 0,
          },
          {
            label: 'Негативных всего:',
            value: doctorReviewsStatistic?.negative ?? 0,
            highlight: true,
          },
          {
            label: 'Всего отзывов:',
            value: doctorReviewsStatistic?.comments ?? 0,
          },
        ],
        link: '/records/appointments',
      },
    ],
    [
      patientsStatistic,
      appointmentsStatistic,
      teleHealthStatistic,
      waitingListStatistic,
      callMeBackStatistic,
      doctorReviewsStatistic,
    ],
  );

  return (
    <Layout className={styles.MainPageWrapper}>
      <Typography className={styles.NameTitle}>Привет, {user?.profile.given_name}!</Typography>
      <Row className={styles.FilterRow}>
        <Typography className={styles.FilterText}>Вот, что у нас происходит</Typography>
        <CustomDatePicker
          placeholder="Сегодня"
          value={currentDate}
          onChange={handleDateChange}
          disabledDate={disabledDate}
        />
        <BranchFilter value={selectedBranchId} onChange={handleBranchSelect} />
      </Row>
      <Row className={styles.MainStatisticRow}>
        {items.map((item) => (
          <StatisticBlock key={item.title} {...item} />
        ))}
      </Row>
    </Layout>
  );
};
