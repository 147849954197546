import { useEffect } from "react";
import { useNavigate } from "react-router";
import { MicroFrontend } from "../../../../Schedule/TMKScheduleWidget/MicroFrontend/MicroFrontend";

type TMKReportsWidgetProps = {
    sessionId: string;
}

const widgetUrl = window.__RUNTIME_CONFIG__.REACT_APP_TM_REPORTS_URL;

const TMKReportsWidget = ({ sessionId }: TMKReportsWidgetProps) => {
    const history = useNavigate();
    
    const listener = () => {        
        try {
            window?.postMessage(
                {
                    isActualReportsData: true,
                    data: {
                        sessionId: sessionId,
                        instance: "farma"
                    }
                },
                '*'
            );
        } catch (e) {
            console.error(e);
        }
    };

    const loadIframe = (val: any) => {             
        if (val.data === "reportsLoad") listener();
    };

    useEffect(() => {
        if (window.addEventListener) {
            window.addEventListener("message", loadIframe, false);
        }
        return () => window.removeEventListener("message", loadIframe, false);
    }, [sessionId]);

    useEffect(() => {
        if (sessionId) {
            listener();
        }
    }, [sessionId]);

    return <div style={{ height: 'unset' }}>
        {sessionId && <MicroFrontend history={history} host={widgetUrl ?? ""} name="TMReports" isVite={true} />}
    </div>;
};

export default TMKReportsWidget;
