import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {NavTabs} from 'src/common/components/NavTabs/NavTabs';
import {useParams} from 'react-router';
import {useAppSelector} from "../../../app/hooks";
import {getPageVisibility} from "../../../common/utils/utils";
import {AccessDeniedStub} from "../../../common/components/AccessDeniedStub/AccessDeniedStub";
import {ReportsPage} from "./ReportsPage/ReportsPage";
import {DocGenPage} from "./TMKDocGenPage/DocGenPage";
import {SchedulePage} from "./SchedulePage/SchedulePage";
import {useGetClinicalResearchPracticeBranchQuery} from "../../../app/services/api";
import {Spin} from "antd";

function ResearchSettingsPage() {
    const {tab} = useParams();
    const [tabsItems, setTabsItems] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<any>();
    const permissions = useAppSelector((state) => state.app.permissions) || [];
    const role = useAppSelector((state) => state.app.role);
    const isPageVisible = getPageVisibility(permissions, 'can-access-page-clinical-research');

    const { data: clinicalResearchBranch, isLoading } = useGetClinicalResearchPracticeBranchQuery('');
    
    useEffect(() => {
        const items: any[] = [];
        const isAuditor = role === 4;
        const isSponsor = role === 5;
        if (isSponsor) {
            items.push({
                label: 'Управление КИ',
                key: 'clinicResearch',
            });
            items.push({
                label: 'Шаблоны документов',
                key: 'templates',
            });
        }
        if (isAuditor || isSponsor) {
            items.push({
                label: 'Аудит КИ',
                key: 'cabinet',
            });
        }
        setTabsItems(items);
    }, [role]);
    
    useEffect(() => {
        if (tabsItems.length > 0) {
            if (tab && tabsItems.map(x => x.key).includes(tab)) {
                setActiveTab(tab);
            } else {
                setActiveTab(tabsItems[0].key);
            }
        }
    }, [tabsItems, tab]);

    return (
        <div className={styles.pageContainer}>
            {isLoading ? (
                <Spin size={'large'} />
            ) : (isPageVisible && clinicalResearchBranch?.isClinicalResearchServiceEnabled ?
                <>
                    <NavTabs activeTabKey={activeTab} items={tabsItems}
                             basicPath="services/research/settings"/>
                    <>
                        {activeTab && activeTab === 'cabinet' && <><ReportsPage /></>}
                        {activeTab && activeTab === 'templates' && <><DocGenPage /></>}
                        {activeTab && activeTab === 'clinicResearch' && <><SchedulePage /></>}
                    </>
                </>
                :
                <div className={styles.Stub}>
                    <AccessDeniedStub
                        title="У вас не хватает прав для доступа к настройкам сервиса"
                        text="Для работы с сервисом «Клинические исследования» требуется получение дополнительных прав. Пожалуйста, обратитесь к вашему менеджеру или в службу техподдержки N3.Health"
                    />
                </div>
            )}
        </div>
    );
}

export default ResearchSettingsPage;
