import { AccessDeniedStub } from 'src/common/components/AccessDeniedStub/AccessDeniedStub';
import {BranchInWidgetEntity, PromotionDoctors, WidgetDoctors} from 'src/common/types';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload,
  notification, List, Image,
} from 'antd';
import { CustomInput } from 'src/common/components/CustomInput/CustomInput';
import { ReactComponent as DownCaretIcon } from '../../../../common/Icons/DownCaretIcon.svg';
import { ReactComponent as EditIcon } from '../../../../common/Icons/EditIcon.svg';
import { PhoneInput } from 'src/common/components/PhoneInput/PhoneInput';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/QuestionMarkIcon.svg';
import { SketchPicker } from 'react-color';
import { getPageVisibility } from 'src/common/utils/utils';
import {
  useAddTermsOfServiceDataMutation,
  useAddUserAgreementDataMutation,
  useDeleteWidgetBannerBackgroundMutation, useGetPromotionsDoctorsQuery,
  useGetTermsOfServiceDataQuery,
  useGetUserAgreementDataQuery,
  useGetWidgetBannerSettingsQuery,
  useGetWidgetCodeQuery,
  useGetWidgetColorQuery,
  useGetWidgetDoctorsQuery,
  useGetWidgetGeneralSettingsQuery,
  useGetWidgetPreviewUrlQuery,
  useLazyGetWidgetCodeQuery,
  useRemoveWidgetAgreementDocMutation,
  useRemoveWidgetLogoMutation,
  useRemoveWidgetTermsDocMutation,
  useUpdateWidgetBannerSettingsMutation,
  useUpdateWidgetGeneralSettingsMutation,
  useUploadWidgetBannerBackgroundMutation,
  useUploadWidgetLogoMutation,
} from '../../../../app/services/api';
import { useAppSelector } from '../../../../app/hooks';
import { useForm } from 'antd/lib/form/Form';
import Checkbox from 'antd/es/checkbox/Checkbox';
import FloatLabelInput from '../../../../common/FloatLabelInput/FloatLabelInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VirtualList from 'rc-virtual-list';
import FallbackUserPhoto from "../../../../common/Icons/FallbackUserPhoto.svg";
import { ReactComponent as Warn } from '../../../../assets/Warn.svg';
import styles from './styles.module.scss';

const instruction = (
  <>
    <p>
      <b>ВАЖНО: </b>{' '}
      <span>
        рекомендуем устанавливать виджет онлайн-записи на сайт сети клиник/клиники, когда уже все филиалы Вашей сети
      </span>
      <br />
      <span>
        подключены службой поддержки MILA. Кроме того, для каждого подключенного филиала из МИС выгружены и обновлены:
      </span>
      <br />
      <ul>
        <li>перечень специализаций;</li>
      </ul>
      <ul>
        <li>списки услуг;</li>
      </ul>
      <ul>
        <li>списки врачей и специалистов.</li>
      </ul>
      <span>
        Если Вы считаете, что данные в указанных разделах не отображаются по ошибке, пожалуйста, запустите синхронизацию
      </span>
      <br />
      <span>с МИС вручную, или обратитесь в службу поддержки.</span>
    </p>

    <p>
      <span>Убедившись, что вышеуказанные подготовительные шаги успешно выполнены, осуществите следующую </span>
      <br />
      <span>последовательность действий:</span>
      <ol>
        <li>Войдите в Настройки виджета онлайн-записи в Личном кабинете сети клиник/клиники.</li>

        <li>При настройке виджета выбирайте нужные Вам опции и варианты.</li>

        <li>Завершив выбор настроек виджета, сохраните их по клику на «Сохранить изменения».</li>

        <li>
          <span>
            По кнопке «Посмотреть виджет по тестовой ссылке» Вам доступен предпросмотр виджета с учетом сохраненных
          </span>
          <br />
          <span>
            настроек и реальных данных о филиалах и услугах, получаемых из МИС. Проверьте корректность отображения
          </span>
          <br />
          <span>данных в виджете. При необходимости вернитесь к заполнению данных или настройке виджета.</span>
          <br />
        </li>

        <li>
          <span>Скопируйте код виджета в разделе «Код для вставки виджета на сайт».</span>
          <br />
          <span>Это можно сделать с помощью кнопки «Скопировать код» или другим удобным для Вас способом.</span>
        </li>

        <li>
          <span>Войдите в административную панель управления Вашего сайта и вставьте скопированный HTML-код перед</span>
          <br />
          <span>закрывающем тегом &lt;body&gt; на те страницы Вашего сайта, куда Вы хотите подключить систему.</span>
          <br />
          <span>Чаще всего (но не всегда) код нужно вставлять в «шаблон» сайта.</span>
        </li>

        <li>
          <span>
            Если всё сделано правильно, на всех выбранных в предыдущем пункте страницах Вашего сайта появится кнопка
          </span>
          <br />
          <span>«Book now». По клику на эту кнопку посетителям Вашего сайта будет доступен виджет онлайн-записи.</span>
        </li>
      </ol>
    </p>

    <p>
      <span>
        Если в процессе установки кода виджета онлайн-записи на сайт сети клиник/клиники Вы столкнулись с техническими
      </span>
      <br />
      <span>проблемами, обратитесь, пожалуйста, в службу поддержки.</span>
    </p>

    <p>
      <b>ВАЖНО: </b>
      <span>
        если вы установили код на сайт, а потом поменяли настройки виджета, <b>код на сайте обновлять не нужно.</b>
      </span>
    </p>
  </>
);

const renderDoctorSpecialities = (doctorItem: WidgetDoctors) => (
    <Col className={styles.SpecializationList}>
      {([...new Set(doctorItem.specializations)] as any).reduce((p: string, c: string, index: number) => [
        <Typography.Text key={index}>
          <Typography.Text>{p}</Typography.Text>
          <br />
          <Typography.Text>{c}</Typography.Text>
        </Typography.Text>,
      ])}
    </Col>
);

function WidgetSettingsPage() {
  const [form] = useForm<any>();

  const [fieldValues, setFieldValue] = useState<any>({
    showInWidget: false,
  });
  const [logoPath, setLogoPath] = useState<string>('');
  const [widgetCode, setWidgetCode] = useState<string>('');
  const [bannerPath, setBannerPath] = useState<string>('');
  // const [userAgreementId, setUserAgreementId] = useState<string>('');
  // const [termsOfServiceId, setTermsOfServiceId] = useState<string>('');
  const [isSubmitDisabled, setSubmitBtnDisabled] = useState(true);
  const [widgetColor, setWidgetColor] = useState('#6266A7');
  const [widgetDoctorsBackgroundColor, setWidgetDoctorsBackgroundColor] = useState('#fff');
  const [widgetDoctorsBorderColor, setWidgetDoctorsBorderColor] = useState('#fff');
  const [bannerUploadError, setBannerUploadError] = useState<string>('');
  const {
    data: widgetGeneralSettings,
    refetch: refetchGeneralSettings,
    isLoading: isWidgetSettingsLoading,
  } = useGetWidgetGeneralSettingsQuery('');
  const { data: widgetBannerSettings, refetch: refetchBannerSettings } = useGetWidgetBannerSettingsQuery('');
  const { data: widgetColorHex, refetch: refetchWidgetColor } = useGetWidgetColorQuery('');
  const { data: widgetCodeData, refetch: refetchWidgetCode } = useGetWidgetCodeQuery({});
  const [getWidgetCode] = useLazyGetWidgetCodeQuery();
  const { data: widgetPreviewUrl, refetch: refetchWidgetPreviewUrl } = useGetWidgetPreviewUrlQuery('');
  const [updateWidgetGeneralSettings, generalSettingsRequest] = useUpdateWidgetGeneralSettingsMutation();
  const [updateWidgetBannerSettings, bannerSettingsRequest] = useUpdateWidgetBannerSettingsMutation();
  const [uploadWidgetBannerBackground] = useUploadWidgetBannerBackgroundMutation();
  const [uploadWidgetLogo] = useUploadWidgetLogoMutation();
  const [removeWidgetLogo] = useRemoveWidgetLogoMutation();
  // const [removeWidgetAgreementDoc] = useRemoveWidgetAgreementDocMutation();
  // const [removeWidgetTermsDoc] = useRemoveWidgetTermsDocMutation();
  // const [addUserAgreementData] = useAddUserAgreementDataMutation();
  // const [addTermsOfServiceData] = useAddTermsOfServiceDataMutation();
  const [deleteWidgetBannerBackground] = useDeleteWidgetBannerBackgroundMutation();

  // const { data: termsOfServiceDocs, refetch: refetchTermsOfServiceDocs } = useGetTermsOfServiceDataQuery('');
  // const { data: userAgreementDocs, refetch: refetchUserAgreementDocs } = useGetUserAgreementDataQuery('');

  const { data: doctorsList } = useGetWidgetDoctorsQuery({branches: fieldValues.availableBranches?.filter(x => x.showInWidget)?.map(x => x.medOrganizationId)}, {skip: !fieldValues.availableBranches});

  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const isPageVisible = getPageVisibility(permissions, 'can-access-page-settings-service-widget');

  const setDefaultValues = useCallback(() => {
    form.setFieldsValue({
      orgName: widgetGeneralSettings?.practiceNameInWidget,
      phone: widgetGeneralSettings?.practicePhoneInWidget,
      email: widgetGeneralSettings?.practiceEmailInWidget,
      practiceDoctorsWidgetWidth: widgetGeneralSettings?.practiceDoctorsWidgetWidth ?? 900,
      practiceDoctorsWidgetHeight: widgetGeneralSettings?.practiceDoctorsWidgetHeight ?? 690,
      widgetHeader: widgetBannerSettings?.title,
      widgetButtonName: widgetBannerSettings?.buttonTitle,
      widgetDescription: widgetBannerSettings?.description,
      widgetButtonUrl: widgetBannerSettings?.buttonUrl,
      showInWidget: Boolean(widgetBannerSettings?.showInWidget),
      widgetCode: widgetCodeData,
    });
  }, [
    form,
    widgetBannerSettings?.buttonTitle,
    widgetBannerSettings?.buttonUrl,
    widgetBannerSettings?.description,
    widgetBannerSettings?.showInWidget,
    widgetBannerSettings?.title,
    widgetCodeData,
    widgetGeneralSettings?.practiceNameInWidget,
    widgetGeneralSettings?.practicePhoneInWidget,
    widgetGeneralSettings?.practiceEmailInWidget,
    widgetGeneralSettings?.practiceDoctorsWidgetWidth,
    widgetGeneralSettings?.practiceDoctorsWidgetHeight,
  ]);

  useEffect(() => {
    setDefaultValues();
    setFieldValue({
      showInWidget: widgetBannerSettings?.showInWidget,
      availableBranches: widgetGeneralSettings?.practiceBranchesVisibilityOnWidget,
    });
    if (widgetGeneralSettings) {
      setLogoPath(widgetGeneralSettings.practiceLogoStaticFileId);
    }

    if (widgetBannerSettings) {
      setBannerPath(widgetBannerSettings.backGroundFileId);
    }

    if (widgetColorHex?.backgroundColor) {
      setWidgetColor(widgetColorHex.backgroundColor);
    }

    if (widgetColorHex?.doctorsBackgroundColor) {
      setWidgetDoctorsBackgroundColor(widgetColorHex.doctorsBackgroundColor);
    }

    if (widgetColorHex?.doctorsBorderColor) {
      setWidgetDoctorsBorderColor(widgetColorHex.doctorsBorderColor);
    }

    if (widgetCodeData) {
      setWidgetCode(widgetCodeData);
    }

    // if (userAgreementDocs?.staticFileId) {
    //   setUserAgreementId(userAgreementDocs.staticFileId);
    // }
    //
    // if (termsOfServiceDocs?.staticFileId) {
    //   setTermsOfServiceId(termsOfServiceDocs.staticFileId);
    // }
  }, [
    widgetGeneralSettings,
    widgetBannerSettings,
    widgetColorHex,
    widgetCodeData,
    // userAgreementDocs?.staticFileId,
    // termsOfServiceDocs?.staticFileId,
    setDefaultValues,
  ]);

  const orderedBranchesList = useMemo(
    () =>
      fieldValues.availableBranches
        ? [...fieldValues.availableBranches].sort((a: BranchInWidgetEntity, b: BranchInWidgetEntity) => {
            return a.medOrganizationShortName?.localeCompare(b.medOrganizationShortName);
          })
        : [],
    [fieldValues.availableBranches],
  );

  const onFormValuesChange = () => {
    setSubmitBtnDisabled(false);
  };

  const handleLogoChange = (options: any) => {
    if (options.file) {
      uploadWidgetLogo({
        file: options.file,
        networkCode: networkId,
        businessCode: null,
      })
          .unwrap()
          .then((id: string) => {
            setLogoPath(id);
          });
    }
  };

  const handleBgChange = (options: any) => {
    if (options.file) {
      uploadWidgetBannerBackground({
        file: options.file,
      })
        .unwrap()
        .then((id: string) => {
          setBannerPath(id);
          if ((form.getFieldValue('showInWidget') && bannerPath) || !form.getFieldValue('showInWidget')) {
            updateWidgetBannerSettings({
              showInWidget: form.getFieldValue('showInWidget'),
              title: form.getFieldValue('widgetHeader'),
              description: form.getFieldValue('widgetDescription'),
              buttonTitle: form.getFieldValue('widgetButtonName'),
              buttonUrl: form.getFieldValue('widgetButtonUrl'),
            })
              .unwrap()
              .then(() => {
                refetchBannerSettings();
              })
              .catch((error) => notification.error({ message: error.data.detail }));
          }
        });
    }
  };

  const handleSubmitForm = (v: any) => {
    updateWidgetGeneralSettings({
      practiceNameInWidget: v.orgName,
      practicePhoneInWidget: v.phone,
      practiceEmailInWidget: v.email,
      practiceBranchesVisibilityInWidget: fieldValues.availableBranches,
      practiceBackgroundColor: widgetColor,
      practiceDoctorsBackgroundColor: widgetDoctorsBackgroundColor,
      practiceDoctorsBordersColor: widgetDoctorsBorderColor,
      practiceDoctorsWidgetWidth: v.practiceDoctorsWidgetWidth,
      practiceDoctorsWidgetHeight: v.practiceDoctorsWidgetHeight
    })
      .unwrap()
      .then(() => {
        refetchGeneralSettings();
        refetchWidgetColor();
        notification.success({message: 'Настройки успешно обновлены'});
      })
      .catch((error) => notification.error({ message: error.data.detail }));

    if ((v.showInWidget && bannerPath) || !v.showInWidget) {
      updateWidgetBannerSettings({
        showInWidget: v.showInWidget,
        title: v.widgetHeader,
        description: v.widgetDescription,
        buttonTitle: v.widgetButtonName,
        buttonUrl: v.widgetButtonUrl,
      })
        .unwrap()
        .then(() => {
          refetchBannerSettings();
        })
        .catch((error) => notification.error({ message: error.data.detail }));
    }
  };

  const handleChangeLogo = () => {
    document.getElementById('logoUpload')?.click();
  };

  const handleChangeBackground = () => {
    document.getElementById('backgroundUpload')?.click();
  };

  const handleLogoRemove = () => {
    setLogoPath('');
    removeWidgetLogo({ networkCode: networkId, businessCode: '' });
  };

  const renderColorOption = (colorHex: string) => {
    return (
      <div className={styles.colorSelectorWrapper}>
        <div className={styles.coloredSquare} style={{ background: colorHex }}></div>
        <span>{colorHex}</span>
      </div>
    );
  };

  const checkLogoType = (file: any) => {
    const isLt05M = file.size / 1024 / 1024 < 0.5;
    const isProperFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/webp' ||
      file.type === 'image/svg+xml' ||
      file.type === 'image/svg' ||
      file.type === 'image/avif';
    if (!isLt05M) {
      notification.error({ message: 'Размер файла не должен превышать 500КБ!' });
      return false;
    }
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
      return false;
    }
    return true;
  };

  const checkBgPhotoType = (file: any) => {
    const isLt05M = file.size / 1024 / 1024 < 0.5;

    const isProperFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg' ||
      file.type === 'image/gif' ||
      file.type === 'image/svg+xml';
    if (!isLt05M) {
      notification.error({ message: 'Размер файла не должен превышать 500КБ!' });
      return false;
    }
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
      return false;
    }
    return true;
  };

  const handleShowWidgetByLink = () => {
    window.open(widgetPreviewUrl, '_blank', 'noopener,noreferrer');
  };

  // const handleAgreementDownload = (fileId?: string) => {
  //   if (fileId) {
  //     window.open(
  //       `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${fileId}?inline=true`,
  //       '_blank',
  //       'noopener,noreferrer',
  //     );
  //   }
  // };
  //
  // const handleRemoveAgreementDocument = () => {
  //   removeWidgetAgreementDoc('')
  //     .unwrap()
  //     .then(() => {
  //       setUserAgreementId('');
  //       refetchUserAgreementDocs();
  //     });
  // };
  //
  // const handleRemoveWidgetTermsDoc = () => {
  //   removeWidgetTermsDoc('')
  //     .unwrap()
  //     .then(() => {
  //       setTermsOfServiceId('');
  //       refetchTermsOfServiceDocs();
  //     });
  // };
  //
  // const handleAgreementDocumentSelection = (event: any) => {
  //   addUserAgreementData({ file: event.file.originFileObj })
  //     .unwrap()
  //     .then(() => {
  //       refetchUserAgreementDocs();
  //     });
  // };
  //
  // const handleTermsDocumentSelection = (event: any) => {
  //   addTermsOfServiceData({ file: event.file.originFileObj })
  //     .unwrap()
  //     .then(() => {
  //       refetchTermsOfServiceDocs();
  //     });
  // };
  //
  // const checkUploadDocType = (file: any) => {
  //   const isLt05M = file.size / 1024 / 1024 < 0.5;
  //
  //   const isProperFormat = file.type === 'application/pdf';
  //
  //   if (!isLt05M) {
  //     notification.error({ message: 'Размер файла не должен превышать 500КБ!' });
  //   }
  //   if (!isProperFormat) {
  //     notification.error({ message: 'Формат файла не поддерживается!' });
  //   }
  // };

  const handleBranchVisibilityToggle = (id: string) => {
    const newBranchesArr = fieldValues.availableBranches.map((branch: any, index: number) => {
      return branch.medOrganizationId === id
        ? { ...branch, showInWidget: !fieldValues.availableBranches[index].showInWidget }
        : branch;
    });

    setFieldValue({ ...fieldValues, availableBranches: newBranchesArr });
  };

  const handleBannerRemove = () => {
    deleteWidgetBannerBackground(bannerPath).then(() => {
      setBannerPath('');
    });
  };

  return (
    <div className={styles.pageContainer}>
      {isPageVisible ? (
        <Form form={form} layout="vertical" onFinish={handleSubmitForm} onValuesChange={onFormValuesChange}>
          <div className={styles.generalDataContainer}>
            <Col className={styles.generalDataFormTitle}>
              <Typography.Text>Общие данные</Typography.Text>
            </Col>
            <Row gutter={24} className={styles.inputsRowWrapper}>
              <Row className={styles.singleFormRow}>
                <Col className={styles.inputsWrapper}>
                  <Form.Item name="orgName">
                    <CustomInput floatLabel="Название организации в виджете" maxLength={65} className={styles.Input} />
                  </Form.Item>
                  
                  <Form.Item name="email" rules={[
                    {
                      message: 'Введите корректную электронную почту',
                      pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                    }
                  ]}>
                    <CustomInput floatLabel="Email для отправки запросов на обратный звонок" className={styles.Input} />
                  </Form.Item>

                  <Col className={styles.generalDataFormSubTitle}>
                    <Row>
                      <Typography.Text>Филиалы для показа в виджете</Typography.Text>
                    </Row>
                    <Row className={styles.branchesNamesList}>
                      <Col span={24} className={styles.checkboxesContainer}>
                        {orderedBranchesList?.map((branch: any) => (
                          <Col span={12} key={branch.medOrganizationId}>
                            <Form.Item name={branch.medOrganizationId} className={styles.branchNameChkbWrapper}>
                              <Checkbox
                                checked={branch.showInWidget}
                                onChange={(e) => {
                                  handleBranchVisibilityToggle(e.target.id!);
                                }}
                              >
                                {branch.medOrganizationShortName}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Col>

                <Col>
                  <FloatLabelInput
                    label="Единый номер телефона для пациентов"
                    value={form.getFieldValue('phone') || '8'}
                  >
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          message: 'Пожалуйста, введите корректный номер телефона',
                          pattern: /(\d|\+\d) \(\d{3}\) \d{3}-\d{4}/,
                        },
                      ]}
                    >
                      <PhoneInput
                        name="phone"
                        floatLabel="Единый номер телефона для пациентов"
                        className={styles.Input}
                      />
                    </Form.Item>
                  </FloatLabelInput>
                </Col>
              </Row>

              <Row className={styles.logoPhotoRow}>
                <Col>
                  <Form.Item>
                    <Upload.Dragger
                      className={styles.fileUploader}
                      accept={'.jpg, .jpeg, .svg, .png, .avif, .webp'}
                      maxCount={1}
                      customRequest={(options) => handleLogoChange(options)}
                      beforeUpload={(file) => checkLogoType(file)}
                      showUploadList={{ showRemoveIcon: false }}
                      openFileDialogOnClick={false}
                      id="logoUpload"
                    >
                      {logoPath ? (
                        <img
                          alt=""
                          src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${logoPath}?inline=true`}
                          height={136}
                          width={145}
                        />
                      ) : (
                        <p>Загрузите логотип</p>
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                  <div className={styles.imgPhotoIcons}>
                    <Tooltip
                      title={
                        'Размер загружаемого файла - не более 500 Кб. Загруженный логотип будет автоматически уменьшен до 32 пикселей по высоте и отобразится в виджете слева от текствого наименования организации. Рекомендуемый формат - SVG, допустимы также PNG, JPEG'
                      }
                      placement="right"
                    >
                      <QuestionMarkIcon />
                    </Tooltip>

                    <EditIcon
                      onClick={() => {
                        handleChangeLogo();
                      }}
                    />
                  </div>
                  <Popconfirm
                    title={`Вы уверены, что хотите удалить логотип виджета?`}
                    onConfirm={handleLogoRemove}
                    okText="Да"
                    cancelText="Нет"
                  >
                    {logoPath && (
                      <div className={styles.removePhotoLabel}>
                        <Typography.Text>Удалить логотип</Typography.Text>
                      </div>
                    )}
                  </Popconfirm>
                </Col>
              </Row>
            </Row>
          </div>

          <div className={styles.bannerForm}>
            <Col className={styles.generalDataFormTitle}>
              <Typography.Text>Настройки баннера на стартовой странице виджета</Typography.Text>
            </Col>
            <Row gutter={24} className={styles.inputsRowWrapper}>
              <Col>
                <Form.Item name="showInWidget" className={styles.bannerVisibilityChkbWrapper} valuePropName={'checked'}>
                  <Checkbox
                    checked={fieldValues.showInWidget}
                    onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id!]: e.target.checked })}
                  >
                    Отображать баннер на стартовой странице виджета
                  </Checkbox>
                </Form.Item>
              </Col>

              <Row className={styles.singleFormRow}>
                <Col>
                  <Form.Item name="widgetHeader">
                    <CustomInput floatLabel="Заголовок" className={styles.Input} maxLength={40} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="widgetDescription">
                    <CustomInput floatLabel="Описание" className={styles.Input} maxLength={70} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className={styles.singleFormRow}>
                <Col>
                  <Form.Item name="widgetButtonName">
                    <CustomInput floatLabel="Название кнопки" className={styles.Input} maxLength={30} />
                  </Form.Item>
                </Col>
                <Col>
                  <FloatLabelInput label="URL для кнопки" value={form.getFieldValue('widgetButtonUrl')}>
                    <Form.Item name="widgetButtonUrl">
                      <CustomInput floatLabel="URL для кнопки" className={styles.Input} />
                    </Form.Item>
                  </FloatLabelInput>
                </Col>
              </Row>
            </Row>
            <Row className={styles.backgroundPhotoRow}>
              <Col>
                <Form.Item>
                  <Upload.Dragger
                    className={styles.fileBackgroundUploader}
                    accept={'.jpg, .jpeg, .svg, .png, .avif, .webp'}
                    maxCount={1}
                    customRequest={(options) => handleBgChange(options)}
                    beforeUpload={(file) => checkBgPhotoType(file)}
                    showUploadList={{ showRemoveIcon: false }}
                    openFileDialogOnClick={false}
                    id="backgroundUpload"
                  >
                    {bannerPath ? (
                      <img
                        alt=""
                        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${bannerPath}?inline=true`}
                        height={126}
                        width={410}
                      />
                    ) : (
                      <p>
                        Загрузите фон
                        <br /> (обязательно, если отображение баннера включено)
                      </p>
                    )}
                  </Upload.Dragger>
                </Form.Item>
                <div className={styles.bgPhotoIcons}>
                  <Tooltip
                    title={
                      'Размер загружаемого файла - не более 500 Кб. Размер изображения 410х120 пикселей, рекомендуемый формат - SVG, допустимы также PNG, GIF, JPEG'
                    }
                    placement="right"
                  >
                    <QuestionMarkIcon />
                  </Tooltip>

                  <EditIcon
                    onClick={() => {
                      handleChangeBackground();
                    }}
                  />
                </div>
                {((form.getFieldValue('showInWidget') && !bannerPath) || bannerUploadError) && (
                  <Typography.Text type="danger">
                    {bannerUploadError ? bannerUploadError : 'Пожалуйста, загрузите фон баннера'}
                  </Typography.Text>
                )}
                <Popconfirm
                  title={`Вы уверены, что хотите удалить фон баннера виджета?`}
                  onConfirm={handleBannerRemove}
                  okText="Да"
                  cancelText="Нет"
                >
                  {bannerPath && (
                    <div className={styles.removePhotoLabel}>
                      <Typography.Text>Удалить фон баннера</Typography.Text>
                    </div>
                  )}
                </Popconfirm>
              </Col>
            </Row>
          </div>

          <div className={styles.colorForm}>
            <Col className={styles.generalDataFormTitle}>
              <Typography.Text>Цветовая схема виджета</Typography.Text>
            </Col>
            <Row gutter={24} className={styles.inputsRowWrapper}>
              <Row className={styles.singleColorFormRow}>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(widgetColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={widgetColor}
                      onChangeComplete={(color) => {
                        setWidgetColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  dropdownMatchSelectWidth={225}
                />
                <Tooltip
                  title={
                    'Выберите базовый цвет - для основных элементов виджета (кнопок, иконок, ссылок), а остальные элементы автоматически адаптируются под него. Рекомендуем, чтобы базовый цвет был достаточно контрастным.'
                  }
                  placement="right"
                >
                  <QuestionMarkIcon />
                </Tooltip>
              </Row>
            </Row>
          </div>

            <div className={styles.colorForm}>
              <Col className={styles.generalDataFormTitle}>
                <Typography.Text>Настройки для виджета списка врачей в интерфейсе сайта</Typography.Text>
              </Col>
              <Row gutter={[25, 25]} className={styles.inputsRowDoctorsWrapper}>
                <Row>
                <Col className={styles.generalDataColorTitle}>
                  <Typography.Text>Цвет фона</Typography.Text>
                </Col>
                <Col className={styles.colorFormRow}>
                  <Select
                      style={{height: '56px', width: '187px'}}
                      className={styles.selectorColorWrapper}
                      suffixIcon={<DownCaretIcon/>}
                      value={{label: renderColorOption(widgetDoctorsBackgroundColor), value: ''}}
                      labelInValue={true}
                      dropdownAlign={{offset: [-20, -20]}}
                      dropdownRender={() => (
                          <SketchPicker
                              color={widgetDoctorsBackgroundColor}
                              onChangeComplete={(color) => {
                                setWidgetDoctorsBackgroundColor(color.hex);
                                setSubmitBtnDisabled(false);
                              }}
                          />
                      )}
                      popupClassName={styles.colorPallete}
                      dropdownMatchSelectWidth={225}
                  />
                </Col>
                </Row>
                <Row>
                <Col className={styles.generalDataColorTitle}>
                  <Typography.Text>Цвет бордера</Typography.Text>
                </Col>
                <Col className={styles.colorFormRow}>
                  <Select
                      style={{height: '56px', width: '187px'}}
                      className={styles.selectorColorWrapper}
                      suffixIcon={<DownCaretIcon/>}
                      value={{label: renderColorOption(widgetDoctorsBorderColor), value: ''}}
                      labelInValue={true}
                      dropdownAlign={{offset: [-20, -20]}}
                      dropdownRender={() => (
                          <SketchPicker
                              color={widgetDoctorsBorderColor}
                              onChangeComplete={(color) => {
                                setWidgetDoctorsBorderColor(color.hex);
                                setSubmitBtnDisabled(false);
                              }}
                          />
                      )}
                      popupClassName={styles.colorPallete}
                      dropdownMatchSelectWidth={225}
                  />
                </Col>
                </Row>
              </Row>
              <Row gutter={[25, 25]} className={styles.inputsRowDoctorsWrapper} style={{marginBottom: 0}}>
                <Row>
                  <Form.Item name="practiceDoctorsWidgetWidth" rules={[
                    {
                      message: 'Введите корректную ширину',
                      pattern: /^\d+$/,
                    }
                  ]}>
                    <CustomInput floatLabel="Ширина, px" className={styles.Input} />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item name="practiceDoctorsWidgetHeight" rules={[
                    {
                      message: 'Введите корректную высоту',
                      pattern: /^\d+$/,
                    }
                  ]}>
                    <CustomInput floatLabel="Высота, px" className={styles.Input} />
                  </Form.Item>
                </Row>
              </Row>
              <Row>
                <Col className={styles.infoBlock}>
                  <div>
                    <Typography className={styles.infoNote}>
                      <Warn/>
                      Как изменить ширину и высоту для виджета списка врачей
                    </Typography>
                    <div>
                      В отличие от других настроек параметры ширины и высоты для виджета списка врачей не обновляются 
                      на лету. Если ранее вы установили код такого виджета на сайт, после изменения ширины и 
                      высоты нажмите «Сохранить настройки и обновить код», затем «Скопировать код» в блоке 
                      «Виджет для отображения списка врачей в интерфейсе сайта» и переустановите код на вашем сайте. 
                      Настройки ширины и высоты относятся только к виджету списка врачей и не влияют на попап-виджеты.
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* <div className={styles.agreementsForm}>
            <Col className={styles.generalDataFormTitle}>
              <Typography.Text>Пользовательские соглашения и оферты</Typography.Text>
            </Col>
            <Row gutter={24} className={styles.inputsRowWrapper}>
              <Row className={styles.singleColorFormRow}>
                <Row className={styles.agreementsFormSubTitle}>
                  <Typography.Text>
                    Добавьте соглашение, которое принимает пользователь при записи через виджет
                  </Typography.Text>
                </Row>
                <Tooltip title={'Формат загружаемого файла - PDF, размер - не более 500 Кб.'} placement="right">
                  <QuestionMarkIcon />
                </Tooltip>
              </Row>

              <Row className={styles.agreementUploadButtonContainer}>
                <Col>
                  <Row>
                    <Form.Item name="uploadTermsDoc">
                      {!termsOfServiceId ? (
                        <Upload
                          listType="text"
                          onChange={handleTermsDocumentSelection}
                          fileList={[]}
                          showUploadList={{ showRemoveIcon: false }}
                          className={styles.uploadDocumentForm}
                          beforeUpload={(file) => checkUploadDocType(file)}
                        >
                          <Button className={styles.buttonUploadAgreement} onClick={() => {}}>
                            Загрузить пользовательское соглашение
                          </Button>
                        </Upload>
                      ) : (
                        <Row>
                          <Col>
                            <Button
                              icon={<CheckMarkButtonIcon />}
                              className={styles.buttonUploadUserAgreementDisabled}
                              disabled
                            >
                              Загружено
                            </Button>
                          </Col>
                          <Col className={styles.docInfoWrapper}>
                            <div className={styles.docNameWrapper}>
                              <Popconfirm
                                title={`Вы уверены, что хотите удалить документ?`}
                                onConfirm={() => handleRemoveWidgetTermsDoc()}
                                okText="Да"
                                cancelText="Нет"
                              >
                                <RemoveIcon className={styles.removeDocumentIcon} />
                              </Popconfirm>{' '}
                              <span
                                role="button"
                                tabIndex={0}
                                onClick={() => handleAgreementDownload(termsOfServiceDocs?.staticFileId)}
                                onKeyUp={() => handleAgreementDownload(termsOfServiceDocs?.staticFileId)}
                              >
                                {termsOfServiceDocs?.name}
                              </span>{' '}
                              <span>({termsOfServiceDocs?.size})</span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Row>

            <Row gutter={24} className={styles.inputsRowWrapper}>
              <Row className={styles.singleColorFormRow}>
                <Row className={styles.addAgreementsFormSubTitle}>
                  <Typography.Text>
                    Добавьте согласие на обработку персональных данных, которое принимает пользователь при записи через
                    виджет
                  </Typography.Text>
                </Row>
                <Tooltip title={'Формат загружаемого файла - PDF, размер - не более 500 Кб.'} placement="right">
                  <QuestionMarkIcon />
                </Tooltip>
              </Row>

              <Row className={styles.agreementUploadUserButtonContainer}>
                <Col>
                  <Row>
                    <Form.Item name="uploadDoc">
                      {!userAgreementId ? (
                        <Upload
                          listType="text"
                          onChange={handleAgreementDocumentSelection}
                          fileList={[]}
                          showUploadList={{ showRemoveIcon: false }}
                          className={styles.uploadDocumentForm}
                          beforeUpload={(file) => checkUploadDocType(file)}
                        >
                          <Button className={styles.buttonUploadUserAgreement} onClick={() => {}}>
                            Загрузить согласие на обработку персональных данных
                          </Button>
                        </Upload>
                      ) : (
                        <Row>
                          <Col>
                            <Button
                              icon={<CheckMarkButtonIcon />}
                              className={styles.buttonUploadUserAgreementDisabled}
                              disabled
                            >
                              Загружено
                            </Button>
                          </Col>
                          <Col className={styles.docInfoWrapper}>
                            <div className={styles.docNameWrapper}>
                              <Popconfirm
                                title={`Вы уверены, что хотите удалить документ?`}
                                onConfirm={() => handleRemoveAgreementDocument()}
                                okText="Да"
                                cancelText="Нет"
                              >
                                <RemoveIcon className={styles.removeDocumentIcon} />
                              </Popconfirm>{' '}
                              <span
                                role="button"
                                tabIndex={0}
                                onClick={() => handleAgreementDownload(userAgreementDocs?.staticFileId)}
                                onKeyUp={() => handleAgreementDownload(userAgreementDocs?.staticFileId)}
                              >
                                {userAgreementDocs?.name}
                              </span>{' '}
                              <span>({userAgreementDocs?.size})</span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Row>
          </div> */}

          <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
            <Col>
              <Button className={styles.buttonCancel} onClick={() => setDefaultValues()}>
                Отмена
              </Button>
            </Col>
            <Col>
              <Button
                className={isSubmitDisabled ? styles.buttonSubmitDisabled : styles.buttonSubmit}
                htmlType="submit"
                disabled={isSubmitDisabled}
                loading={generalSettingsRequest.isLoading || bannerSettingsRequest.isLoading}
              >
                Сохранить настройки и обновить код
              </Button>
            </Col>
          </Row>

          <div className={styles.widgetCodeForm}>
            <Col className={styles.generalDataFormTitle}>
              <Typography.Text>Код для вставки виджета на сайт</Typography.Text>
            </Col>
            <Row gutter={24} className={styles.inputsRowWrapper}>
              <Row className={styles.widgetCodeBtnsWrapper}>
                <Col>
                  <Button className={styles.buttonShowWidget} onClick={() => handleShowWidgetByLink()}>
                    Посмотреть виджет по тестовой ссылке
                  </Button>
                </Col>
              </Row>
              <Row className={styles.instructionDescAccordion}>
                <Col>
                  <Collapse
                      ghost
                      expandIconPosition={'end'}
                      expandIcon={({ isActive }) =>
                          isActive ? <DownCaretIcon className={styles.rotatedIcon} /> : <DownCaretIcon />
                      }
                  >
                    <Collapse.Panel header="Инструкция по внедрению виджета на ваш сайт" key="1">
                      <div className={styles.instructionText}>{instruction}</div>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <Col className={styles.widgetCodeSubTitle}><Typography.Text>Попап-виджет для кнопки «Онлайн запись»</Typography.Text></Col>
                <div className={styles.widgetCodeInfoContainer}>
                  <div>Полная форма виджета. Включает все маршруты записи, поиск, карту филиалов, встроенные элементы навигации. Предпочтительно размещать в модальном окне, открывающемся поверх сайта</div>
                  <Button
                      className={styles.buttonCopyCode}
                      onClick={() => {
                        navigator.clipboard.writeText(widgetCode);
                        notification.success({ message: 'Код для вставки виджета скопирован' });
                      }}
                  >
                    Скопировать код
                  </Button>
                </div>
              </Row>
              <Row>
                <Col className={styles.widgetCodeSubTitle}><Typography.Text>Попап-виджет для кнопки «Записаться» в профиле врача</Typography.Text></Col>
                <Col className={styles.widgetDoctorsText}><Typography.Text>Полная форма виджета, однако стартовой страницей является экран с профилем врача. 
                  Удобно использовать для организации записи к конкретному врачу с сайта клиники. 
                  Предпочтительно размещать в модальном окне, открывающемся поверх сайта, 
                  при клике на кнопку «Записаться» в профиле соответствующего врача</Typography.Text></Col>
                {doctorsList && doctorsList?.length > 0 && 
                        <Col className={styles.DoctorsList}>
                              <List>
                                <VirtualList
                                    data={doctorsList}
                                    itemHeight={85}
                                    itemKey="idMilaDoctor"
                                    className={styles.DoctorsListContainer}
                                >
                                  {(item: WidgetDoctors) => 
                                    <List.Item key={item.idMilaDoctor}>
                                      <List.Item.Meta
                                          avatar={
                                            <Image
                                                preview={false}
                                                src={
                                                  item.idPhotoFile
                                                      ? `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${item.idPhotoFile}?inline=true`
                                                      : FallbackUserPhoto
                                                }
                                                className={styles.DoctorPhoto}
                                            />
                                          }
                                          title={
                                            <Typography.Text className={styles.DoctorNameText}>{item.fullName}</Typography.Text>
                                          }
                                      />
                                      <Row className={styles.ContentWrapper}>
                                        {renderDoctorSpecialities(item)}
                                        <Col className={styles.CopyButtonWrapper}>
                                          <Button className={styles.buttonCopyCode} onClick={() => {
                                            getWidgetCode({doctorId: item.idMilaDoctor}).unwrap().then(res => {
                                              navigator.clipboard.writeText(res);
                                              notification.success({message: 'Код для вставки виджета скопирован'});
                                            });
                                          }}>
                                            Скопировать код
                                          </Button>
                                        </Col>
                                      </Row>
                                    </List.Item>
                                  }
                                </VirtualList>
                              </List>
                        </Col>
                    }
                <Col className={styles.widgetCodeSubTitle}><Typography.Text>Виджет для отображения списка врачей в интерфейсе сайта</Typography.Text></Col>
                <div className={styles.widgetCodeInfoContainer}>
                  <div>Готовый интерфейс со списком врачей, отображением их услуг, расписанием и фильтрами для подбора. Удобно использовать для создания раздела о врачах непосредственно в интерфейсе сайта клиники. </div>
                  <Button
                      className={styles.buttonCopyCode}
                      onClick={() => {
                        getWidgetCode({openDoctorsList: true,
                          widgetWidth: form.getFieldValue('practiceDoctorsWidgetWidth'),
                          widgetHeight: form.getFieldValue('practiceDoctorsWidgetHeight')}).unwrap().then(res => {
                          navigator.clipboard.writeText(res);
                          notification.success({message: 'Код для вставки виджета скопирован'});
                        });
                      }}
                  >
                    Скопировать код
                  </Button>
                </div>
              </Row>
            </Row>
          </div>
        </Form>
      ) : (
        <AccessDeniedStub
          title="Ошибка 403"
          text="Доступ запрещен

          У вас недостаточно прав доступа. Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
        />
      )}
    </div>
  );
}

export default WidgetSettingsPage;
