import { AcademicDegrees, ExtendedFile, PageMode, Props } from './DoctorsInfoPage.types';
import { Popconfirm, notification } from 'antd';
import { PracticeDoctorDocument } from '../../../../common/types';
import {
  PracticeDoctorSpecializationModel,
  PracticeSpecialityWithPositionsModel,
  Select,
  SelectedSpeciality,
  Specialities,
} from './components/Specialities/Specialities.types';
import { ReactComponent as RemoveIcon } from '../../../../common/Icons/RemoveIcon.svg';
import { UploadChangeParam } from 'antd/es/upload';
import {
  checkAllFilledSpecialityAreChecked,
  checkAtLeastOneSpecialityIsFilled,
  createEmptySpeciality,
  getOrganizationInfoBySpecialityPositionId,
  getSizeRepresentation,
  getSpecialityFullName,
  normalizeSpecializationsForCreating,
  normalizeSpecializationsForEditing,
} from './DoctorsInfoPage.utils';
import { isEqual } from 'lodash';
import { oidcAuthService } from '../../../../app/services/auth/oidcAuthService';
import {
  useAccessToDoctorPortalMutation,
  useAddDoctorEducationInfoMutation,
  useDeleteDoctorEducationInfoMutation,
  useGetDoctorContactInfoQuery,
  useGetDoctorDocumentsQuery,
  useGetDoctorEducationInfoQuery,
  useGetDoctorEducationTypesQuery,
  useGetDoctorGeneralInfoQuery,
  useGetDoctorServicesVisibilityQuery,
  useGetDoctorsAcademicDegreesQuery,
  useGetPracticeBranchesQuery,
  useGetSpecialitiesPositionsQuery,
  useLazyGetDoctorDocumentQuery,
  useRemoveDoctorDocumentMutation,
  useRemoveDoctorPhotoMutation,
  useResendVerificationEmailMutation,
  useUpdateDoctorContactInfoMutation,
  useUpdateDoctorEducationInfoMutation,
  useUpdateDoctorGeneralInfoMutation,
  useUpdateDoctorServicesVisibilityMutation,
  useUploadDoctorDocumentsMutation,
  useUploadDoctorPhotoMutation,
} from '../../../../app/services/api';
import { useAppSelector } from '../../../../app/hooks';
import { useForm } from 'antd/lib/form/Form';
import { useGetPositionsQuery } from 'src/app/services/apiDictionary';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from '../styles.module.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions*/

export const useDoctorsInfoPage = (props: Props) => {
  const { pageMode, initialFieldsState } = props;
  const [form] = useForm<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const practiceDoctorId = useMemo((): string | undefined => {
    if (pageMode === PageMode.Creating) return undefined;

    return pathname.split('/').reverse()[1];
  }, [pathname, pageMode]);
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);
  const [fieldValues, setFieldValue] = useState<any>({
    surname: '',
    firstname: '',
    middlename: '',
    birthdate: '',
    position: '',
    snils: '',
    experience: '',
    speciality: '',
    children: '',
    aboutDoctor: '',
    phone: '',
    email: '',
    education: [''],
    showInMilaServices: false,
  });
  const [gender, setGenderValue] = useState<number>(0);
  const [category, setCategoryValue] = useState<number>(0);
  const [degree, setDegreeValue] = useState<string>('');
  const [doctorPhotoId, setDoctorPhotoId] = useState<string>('');
  const [doctorPhotoFile, setDoctorPhotoFile] = useState<File>();
  const [position, setPosition] = useState<string | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const { data: branchesList } = useGetPracticeBranchesQuery('');

  const {
    data: doctorGeneralInfo,
    refetch: refetchDoctorGeneralInfo,
    error: errorDoctorGeneralInfo,
    isLoading,
  } = useGetDoctorGeneralInfoQuery(
    { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
    {
      skip: !practiceId || !practiceDoctorId,
    },
  );
  const { data: doctorsAcademicDegreesData } = useGetDoctorsAcademicDegreesQuery('');
  const { data: specialitiesPositionsData } = useGetSpecialitiesPositionsQuery(practiceId!, { skip: !practiceId });
  const { data: doctorEducationInfo, refetch: refetchDoctorEducationInfo } = useGetDoctorEducationInfoQuery(
    { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
    {
      skip: !practiceId || !practiceDoctorId,
    },
  );

  const [accessToDoctorPortal, accessToDoctorPortalResult] = useAccessToDoctorPortalMutation();
  const [hasAccessToDoctorPortal, setAccessToDoctorPortal] = useState<boolean>(false);

  useEffect(() => {
    setAccessToDoctorPortal(doctorGeneralInfo?.hasAccessToDoctorPortal || false);
  }, [doctorGeneralInfo?.hasAccessToDoctorPortal]);

  useEffect(() => {
    if (accessToDoctorPortalResult?.isSuccess) {
      setAccessToDoctorPortal(!!accessToDoctorPortalResult?.originalArgs?.setAccess);
    }
  }, [accessToDoctorPortalResult.isSuccess]);

  const [resendVerificationEmail, resendVerificationEmailResult] = useResendVerificationEmailMutation();

  const doctorEducationInfoFormatted = useMemo(() => {
    return doctorEducationInfo?.map((education: any) => {
      return {
        idDoctorEducation: education.idDoctorEducation,
        institution: education.institution,
        educationType: education.educationType,
        speciality: education.speciality,
        educationEndDate: dayjs(education.educationEndDate).utc(),
      };
    });
  }, [doctorEducationInfo]);

  const { data: doctorContactInfo, refetch: refetchDoctorContactInfo } = useGetDoctorContactInfoQuery(
    { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
    {
      skip: !practiceId || !practiceDoctorId,
    },
  );

  const { data: doctorServicesVisibilityInfo, refetch: refetchDoctorServicesVisibility } =
    useGetDoctorServicesVisibilityQuery(
      { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
      {
        skip: !practiceId || !practiceDoctorId,
      },
    );

  const { data: doctorDocumentsList, refetch: refetchDoctorDocuments } = useGetDoctorDocumentsQuery(
    { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
    {
      skip: !practiceId || !practiceDoctorId,
    },
  );

  const { data: doctorEducationTypes } = useGetDoctorEducationTypesQuery('');
  const { data: positionsList } = useGetPositionsQuery('');

  const [uploadDoctorPhoto] = useUploadDoctorPhotoMutation();
  const [removeDoctorPhoto] = useRemoveDoctorPhotoMutation();
  const [updateDoctorGeneralInfo] = useUpdateDoctorGeneralInfoMutation();
  const [updateDoctorEducationInfo] = useUpdateDoctorEducationInfoMutation();
  const [deleteDoctorEducationInfo] = useDeleteDoctorEducationInfoMutation();
  const [addDoctorEducationInfo] = useAddDoctorEducationInfoMutation();
  const [updateDoctorContactInfo] = useUpdateDoctorContactInfoMutation();
  const [updateDoctorServicesVisibilityInfo] = useUpdateDoctorServicesVisibilityMutation();
  const [uploadDoctorDocuments] = useUploadDoctorDocumentsMutation();
  const [removeDoctorDocument] = useRemoveDoctorDocumentMutation();
  const [downloadDoctorDocument] = useLazyGetDoctorDocumentQuery();
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';
  const [academicDegrees, setAcademicDegrees] = useState<AcademicDegrees>([]);
  const [downloadedDiplomas, setDownloadedDiplomas] = useState<ExtendedFile[]>([]);

  const setDefaultValues = () => {
    if (doctorGeneralInfo) {
      const selectedPosition = positionsList?.find((item) => {
        const code = Number(/\d+/.exec(doctorGeneralInfo.position));
        return String(code) === item.code;
      });
      setPosition(selectedPosition?.code ?? null);

      form.setFieldsValue({
        surname: doctorGeneralInfo.lastName,
        firstname: doctorGeneralInfo.firstName,
        middlename: doctorGeneralInfo.middleName,
        birthdate: doctorGeneralInfo.birthDay ? dayjs(doctorGeneralInfo?.birthDay) : '',
        position: selectedPosition ? doctorGeneralInfo.position : '',
        snils: doctorGeneralInfo.snils,
        experience: doctorGeneralInfo?.experience ? dayjs(doctorGeneralInfo?.experience) : '',
        speciality: doctorGeneralInfo.specializations,
        aboutDoctor: doctorGeneralInfo?.aboutDoctor,
        gender: doctorGeneralInfo.gender,
        category: doctorGeneralInfo.category,
        degree: doctorGeneralInfo.academicDegreeId,
        specialityYear: [''],
        education: doctorEducationInfoFormatted || [''],
        showInMilaServices: doctorServicesVisibilityInfo?.showInMilaServices,
      });
    }
    if (doctorContactInfo) {
      form.setFieldsValue({
        phone: doctorContactInfo.phone,
        email: doctorContactInfo.email,
      });
    }
  };

  useEffect(() => {
    if (pageMode === PageMode.Creating && initialFieldsState) {
      setFieldValue((prev) => ({
        ...prev,
        ...initialFieldsState,
      }));
      let doctorEducation: any[] = [];
      if (initialFieldsState.education) {
        doctorEducation = [...initialFieldsState.education.map((education: any) => {
          return {
            idDoctorEducation: education.idDoctorEducation,
            institution: education.institution,
            educationType: education.educationType,
            speciality: education.speciality,
            educationEndDate: dayjs(education.educationEndDate).utc(),
          };
        })];
      }
      form.setFieldsValue({
        snils: initialFieldsState.snils,
        firstname: initialFieldsState.firstname,
        surname: initialFieldsState.surname,
        middlename: initialFieldsState.middlename,
        education: doctorEducation,
        category: initialFieldsState.category,
        degree: initialFieldsState.degree,
        birthdate: initialFieldsState.birthdate,
        gender: initialFieldsState.gender,
        experience: initialFieldsState.experience,
      });
      if (initialFieldsState.degree) {
        setDegreeValue(initialFieldsState.degree);
      }
      if (initialFieldsState.category) {
        setCategoryValue(initialFieldsState.category);
      }
      if (initialFieldsState.gender) {
        setGenderValue(initialFieldsState.gender);
      }
      if (initialFieldsState.documents && initialFieldsState.documents.length > 0) {
        const doctorDocs = initialFieldsState?.documents?.map(doc => ({
          file: {name: doc.name, size: doc.size},
          fileId: doc.practiceDoctorDocumentId,
        } as any));
        setDownloadedDiplomas(doctorDocs);
      }
      if (initialFieldsState.photoId) {
        fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${initialFieldsState.photoId}?inline=true`).then(res => {
          return res.blob();
        }).then((res: any) => {
          setDoctorPhotoFile(res);
        });
      }
    }
  }, [initialFieldsState, pageMode]);

  useEffect(() => {
    setDefaultValues();
    if (doctorGeneralInfo && doctorContactInfo && doctorServicesVisibilityInfo && pageMode === PageMode.Editing) {
      setFieldValue({
        surname: doctorGeneralInfo.lastName,
        firstname: doctorGeneralInfo.firstName,
        middlename: doctorGeneralInfo.middleName,
        birthdate: doctorGeneralInfo.birthDay,
        position: doctorGeneralInfo.position,
        snils: doctorGeneralInfo.snils,
        experience: doctorGeneralInfo.experience,
        speciality: doctorGeneralInfo.specializations,
        aboutDoctor: doctorGeneralInfo.aboutDoctor,
        education: doctorEducationInfo || [''],
        showInMilaServices: doctorServicesVisibilityInfo.showInMilaServices,
        phone: doctorContactInfo.phone,
        email: doctorContactInfo.email,
      });
      setGenderValue(doctorGeneralInfo.gender);
      setCategoryValue(doctorGeneralInfo.category);
      setDegreeValue(doctorGeneralInfo.degree);
      setDoctorPhotoId(doctorGeneralInfo.idPhotoFile);
    }
  }, [
    doctorGeneralInfo,
    doctorContactInfo,
    doctorEducationInfo,
    doctorServicesVisibilityInfo,
    doctorEducationTypes,
    pageMode,
  ]);

  const handleSubmitForm = async (v: any) => {
    if (
      !checkAtLeastOneSpecialityIsFilled(selectedSpecialities) ||
      !checkAllFilledSpecialityAreChecked(selectedSpecialities)
    ) {
      notification.error({
        message: 'У врача должна быть выбрана как минимум одна специализация и указан тип (взрослый и/или детский)',
      });
      return;
    }
    
    setSaveLoading(true);
    if (pageMode === PageMode.Creating) {
      const bearer = await oidcAuthService.getToken();
      const body = {
        snils: v.snils.split('-').join(''),
        firstName: v.firstname,
        middleName: v.middlename,
        lastName: v.surname,
        birthDay: v.birthdate,
        gender: v.gender,
        category: v.category,
        jobTitle: v.position,
        experience: v.experience,
        aboutDoctor: v.aboutDoctor,
        academicDegreeId: v.degree,
        specializations: normalizeSpecializationsForCreating(
          specialitiesWithPosition,
          selectedSpecialities || [],
        ) as any,
      };
      const res = await fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/practice/${practiceId}/doctors`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${bearer}`,
          'x-mila-network-id': networkId,
          'x-mila-med-organization-id': medOrganizationId,
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (res.status < 200 || res.status > 300) {
        setSaveLoading(false);
        notification.error({ message: 'При сохранении доктора произошла ошибка' });
        return;
      }
      notification.success({ message: 'Сохранение доктора прошло успешно' });
      const doctor = await res.json();
      await updateDoctorContactInfo({
        phone: v.phone,
        email: v.email,
        practiceId: practiceId!,
        practiceDoctorId: doctor.practiceDoctorId,
      });
      if (doctorPhotoFile) {
        await uploadDoctorPhoto({
          practiceId: practiceId,
          idMilaDoctor: doctor.practiceDoctorId,
          file: doctorPhotoFile,
        });
      }
      if (hasAccessToDoctorPortal) {
        await accessToDoctorPortal({
          practiceId,
          practiceDoctorId: doctor.practiceDoctorId,
          setAccess: hasAccessToDoctorPortal,
        });
      }
      await downloadedDiplomas.forEach(async (currentFile) => {
        await uploadDoctorDocuments({
          practiceId: practiceId,
          doctorId: doctor.practiceDoctorId,
          file: currentFile.file,
        });
      });
      if (!fieldValues.showInMilaServices) {
        await updateDoctorServicesVisibilityInfo({
          showInMilaServices: fieldValues.showInMilaServices,
          practiceId: practiceId!,
          practiceDoctorId: doctor.practiceDoctorId,
        });
      }
      if ((v?.education?.filter(x => !x.idDoctorEducation)?.length ?? 0) > 0) {
        await Promise.all(
          v.education.filter(x => !x.idDoctorEducation).map((educationEntity) => {
            new Promise(() => {
              addDoctorEducationInfo({
                ...educationEntity,
                practiceId,
                practiceDoctorId: doctor.practiceDoctorId,
              });
            });
          }),
        );
      }
      setSaveLoading(false);
      navigate(`/company/doctors/${practiceId ?? ''}/${doctor.practiceDoctorId}/amenitiesDoctor`);
    } else {
      const isGeneralInfoChanged =
        dayjs(v.birthdate).format('DD.MM.YYYY') !== dayjs(doctorGeneralInfo?.birthDay).format('DD.MM.YYYY') ||
        v.gender !== doctorGeneralInfo?.gender ||
        dayjs(v.experience).format('DD.MM.YYYY') !== dayjs(doctorGeneralInfo?.experience).format('DD.MM.YYYY') ||
        v.aboutDoctor !== doctorGeneralInfo?.aboutDoctor ||
        v.degree !== doctorGeneralInfo?.academicDegreeId ||
        v.category !== doctorGeneralInfo?.category ||
        !isEqual(doctorGeneralInfo?.specializations, v.speciality) ||
        v.firstname !== doctorGeneralInfo?.firstName ||
        v.surname !== doctorGeneralInfo?.lastName;

      const isContactInfoChanged = v.phone !== doctorContactInfo?.phone || v.email !== doctorContactInfo?.email;
      const isServicesVisibilityChanged =
        fieldValues.showInMilaServices !== doctorServicesVisibilityInfo?.showInMilaServices;

      if (isGeneralInfoChanged && practiceId) {
        try {
          await updateDoctorGeneralInfo({
            firstName: v.firstname,
            middleName: v.middlename,
            lastName: v.surname,
            birthDay: v.birthdate,
            gender: v.gender,
            category: v.category,
            jobTitle: v.position,
            experience: v.experience,
            aboutDoctor: v.aboutDoctor,
            academicDegreeId: v.degree,
            specializations: normalizeSpecializationsForEditing(
              specialitiesWithPosition,
              selectedSpecialities || [],
            ) as any,
            practiceId: practiceId,
            practiceDoctorId: practiceDoctorId!,
          });
          refetchDoctorGeneralInfo();
          notification.success({ message: 'Общие данные врача успешно обновлены' });
        } catch (error: any) {
          notification.error({ message: error.data.detail });
        }
      }

      if (isContactInfoChanged && practiceId) {
        try {
          await updateDoctorContactInfo({
            phone: v.phone,
            email: v.email,
            practiceId: practiceId,
            practiceDoctorId: practiceDoctorId!,
          });
          refetchDoctorContactInfo();
          notification.success({ message: 'Контактные данные врача успешно обновлены' });
        } catch (error: any) {
          notification.error({ message: error.data.detail });
        }
      }

      if (isServicesVisibilityChanged && practiceId) {
        try {
          await updateDoctorServicesVisibilityInfo({
            showInMilaServices: fieldValues.showInMilaServices,
            practiceId: practiceId,
            practiceDoctorId: practiceDoctorId!,
          });
          refetchDoctorServicesVisibility();
          notification.success({ message: 'Отображение в сервисах успешно обновлено' });
        } catch (error: any) {
          notification.error({ message: error.data.detail });
        }
      }

      if (!isEqual(v.education, doctorEducationInfoFormatted)) {
        Promise.all(
          v.education.map((educationEntity) => {
              return practiceId &&
                (educationEntity.idDoctorEducation
                  ? updateDoctorEducationInfo({
                      ...educationEntity,
                      practiceId: practiceId,
                      practiceDoctorId: practiceDoctorId,
                      practiceDoctorEducationId: educationEntity.idDoctorEducation,
                    }).unwrap().then(result => result).catch((error) => error)
                  : addDoctorEducationInfo({
                      ...educationEntity,
                      practiceId: practiceId,
                      practiceDoctorId: practiceDoctorId,
                    }).unwrap().then(result => result).catch((error) => error));
          }),
        ).then((result: any) => {
          setSaveLoading(false);
          const errors = result?.filter((x: any) => x?.data?.errors);
          if (errors?.length > 0) {
            notification.error({message: errors[0].data.title});
          } else {
            refetchDoctorEducationInfo();
            notification.success({message: 'Данные об образовании врача успешно обновлены'});
          }
        });
      } else {
        setSaveLoading(false);
      }
    }
  };

  const genderLabel = useMemo(() => {
    return gender === 0 ? 'Жен' : 'Муж';
  }, [gender]);

  const handleDoctorPhotoRemove = () => {
    setDoctorPhotoId('');
    setDoctorPhotoFile(undefined);
    if (practiceId && pageMode === PageMode.Editing) {
      removeDoctorPhoto({ practiceId: practiceId, practiceDoctorId: practiceDoctorId! });
    }
  };

  const handleDoctorPhotoChange = (options: any) => {
    if (pageMode === PageMode.Editing) {
      uploadDoctorPhoto({
        practiceId: practiceId,
        idMilaDoctor: practiceDoctorId,
        file: options.file,
      })
        .unwrap()
        .then((id: string) => setDoctorPhotoId(id));
    }
  };

  const handleDocumentSelection = (options: any) => {
    if (pageMode === PageMode.Editing) {
      uploadDoctorDocuments({ practiceId: practiceId, doctorId: practiceDoctorId, file: options.file })
        .unwrap()
        .then(() => refetchDoctorDocuments());
    } else {
      setDownloadedDiplomas((prev) => [
        ...prev,
        {
          file: options.file,
          fileId: new Date().getTime().toString(),
        },
      ]);
    }
  };

  useEffect(() => {
    if (doctorsAcademicDegreesData) {
      setAcademicDegrees(
        doctorsAcademicDegreesData.map((degree: any) => {
          return {
            label: degree.name,
            value: degree.id,
          };
        }),
      );
    }
  }, [doctorsAcademicDegreesData]);

  const prepareDegrees = () => {
    if (pageMode === PageMode.Editing) {
      return doctorGeneralInfo?.availableAcademicDegrees
        ? doctorGeneralInfo?.availableAcademicDegrees.map((degree: any) => {
            return {
              label: degree.name,
              value: degree.id,
            };
          })
        : [];
    } else if (pageMode === PageMode.Creating) {
      return academicDegrees;
    } else {
      return [];
    }
  };

  const prepareEducationTypes = () => {
    return doctorEducationTypes
      ? doctorEducationTypes.map((type: any) => {
          return {
            label: type.name,
            value: type.code,
          };
        })
      : [];
  };

  const handleRemoveDocument = (docId: string) => {
    if (pageMode === PageMode.Editing) {
      if (practiceId) {
        removeDoctorDocument({ practiceId: practiceId, documentId: docId, practiceDoctorId: practiceDoctorId! })
          .unwrap()
          .then(() => refetchDoctorDocuments());
      }
    } else {
      setDownloadedDiplomas((prev) => prev.filter((file) => file.fileId !== docId));
    }
  };

  const handleDocumentDownload = (uid: string) => {
    if (practiceId && pageMode === PageMode.Editing) {
      downloadDoctorDocument({
        documentId: uid,
        practiceId: practiceId,
        practiceDoctorId: practiceDoctorId!,
      });
    }
  };

  const fileList = useMemo(() => {
    const collection: PracticeDoctorDocument[] = [];
    if (pageMode === PageMode.Editing) {
      if (doctorDocumentsList) {
        collection.push(...doctorDocumentsList);
      } else {
        return [];
      }
    } else {
      collection.push(
        ...downloadedDiplomas.map((item) => ({
          name: item.file.name,
          practiceDoctorDocumentId: item.fileId,
          mimeType: '',
          size: typeof(item.file.size) === "number" ? getSizeRepresentation(item.file.size) : item.file.size,
        })),
      );
    }
    return collection.map((doc: PracticeDoctorDocument) => ({
      uid: doc.practiceDoctorDocumentId,
      name: (
        <div className={styles.docNameWrapper}>
          <Popconfirm
            title={`Вы уверены, что хотите удалить документ?`}
            onConfirm={() => handleRemoveDocument(doc.practiceDoctorDocumentId)}
            okText="Да"
            cancelText="Нет"
          >
            <RemoveIcon className={styles.removeDocumentIcon} />
          </Popconfirm>{' '}
          <span onClick={() => handleDocumentDownload(doc.practiceDoctorDocumentId)}>{doc.name}</span>{' '}
          <span>({doc.size})</span>
        </div>
      ),
      status: 'done',
    }));
  }, [doctorDocumentsList, pageMode, downloadedDiplomas]);

  const handleEducationValuesChange = (value: string, fieldKey: number, fieldName: string) => {
    const updatedEdu = [...fieldValues.education];
    const newValue = { ...fieldValues.education[fieldKey] };
    newValue[fieldName] = value;
    updatedEdu.splice(fieldKey, 1, newValue);

    setFieldValue({ ...fieldValues, education: updatedEdu });
  };

  const handleDeleteEducationInfo = (key: number) => {
    fieldValues.education[key] &&
      practiceId &&
      deleteDoctorEducationInfo({
        practiceId: practiceId,
        practiceDoctorId: practiceDoctorId!,
        practiceDoctorEducationId: fieldValues.education[key].idDoctorEducation,
      });
  };

  const handleChangePhoto = () => {
    document.getElementById('doctorPhotoUpload')?.click();
  };

  const checkPhotoType = (file: any) => {
    const isLt1M = file.size / 1024 / 1024 < 1;

    const isProperFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/webp' ||
      file.type === 'image/bmp' ||
      file.type === 'image/svg' ||
      file.type === 'image/avif' ||
      file.type === 'image/tiff';
    if (!isLt1M) {
      notification.error({ message: 'Размер файла не должен превышать 1МБ!' });
      return false;
    }
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
      return false;
    }
    return true;
  };

  const checkUploadDocType = (file: any) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    const isProperFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/pdf' ||
      file.type === 'image/tiff' ||
      file.type === 'application/msword' ||
      file.type === 'image/bmp' ||
      file.type === 'application/vnd.oasis.opendocument.text';
    if (!isLt1M) {
      notification.error({ message: 'Размер файла не должен превышать 1МБ!' });
      return false;
    }
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
      return false;
    }
    return true;
  };

  const handleDeleteDoctorFromBranch = async () => {
    const bearer = await oidcAuthService.getToken();
    await fetch(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/practice/${practiceId}/doctors/${practiceDoctorId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${bearer}`,
          'x-mila-network-id': networkId,
          'x-mila-med-organization-id': medOrganizationId,
        },
      },
    );
    navigate(`/company/doctors/${practiceId ?? ''}`);
  };

  const doctorPhotoSrc = useMemo((): string | undefined => {
    if (pageMode === PageMode.Editing) {
      if (doctorPhotoId) {
        return `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${doctorPhotoId}?inline=true`;
      } else {
        return undefined;
      }
    } else {
      if (doctorPhotoFile) {
        return URL.createObjectURL(doctorPhotoFile);
      } else {
        return undefined;
      }
    }
  }, [doctorPhotoId, doctorPhotoFile, pageMode]);

  const handleAccessToDoctorPortalClick = () => {
    if (pageMode === PageMode.Editing) {
      accessToDoctorPortal({
        practiceId,
        practiceDoctorId,
        setAccess: !hasAccessToDoctorPortal,
      }).then((res: any) => {
        if (res.error.data.detail) {
          notification.error({ message: 'Для создания доступа укажите Email врача' });
        }
      });
    } else {
      setAccessToDoctorPortal((prev) => !prev);
    }
  };

  const handleCancelButtonOnClick = () => {
    if (pageMode === PageMode.Editing) {
      setDefaultValues();
    } else {
      navigate(`/company/doctors/${practiceId ?? ''}`);
    }
  };

  const [optionsSpecialities, setOptionsSpecialities] = useState<Select>([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState<SelectedSpeciality[]>([]);
  const [specialitiesWithPosition, setSpecialitiesWithPosition] = useState<PracticeSpecialityWithPositionsModel>([]);

  useEffect(() => {
    if (specialitiesPositionsData) {
      setSpecialitiesWithPosition(specialitiesPositionsData);

      const specialities: Specialities = [];
      specialitiesPositionsData.forEach((specialityWithPosition) => {
        specialityWithPosition.positions.forEach((position) => {
          specialities.push({
            positionName: position.positionName,
            specialityName: specialityWithPosition.specialityName,
            specialityFerId: specialityWithPosition.specialityFerId,
            specialityPositionId: position.specialityPositionId,
          });
        });
      });
      setOptionsSpecialities(
        specialities.map((speciality) => ({
          label: getSpecialityFullName(speciality.positionName, speciality.specialityName, speciality.specialityFerId),
          value: speciality.specialityPositionId,
        })),
      );
    }
  }, [specialitiesPositionsData]);

  useEffect(() => {
    if (pageMode === PageMode.Editing) {
      if (fieldValues.speciality && specialitiesWithPosition) {
        const tSelectedSpecialities: SelectedSpeciality[] = [];
        fieldValues.speciality.forEach((speciality: PracticeDoctorSpecializationModel) => {
          specialitiesWithPosition.forEach((specialityWithPosition) => {
            specialityWithPosition.positions.forEach((item) => {
              if (item.specialityPositionId === speciality.doctorSpecializationPositionId) {
                tSelectedSpecialities.push({
                  uid: Math.random().toString(),
                  specializationId: speciality.doctorSpecializationId,
                  specialityPositionId: speciality.doctorSpecializationPositionId,
                  specialityFerId: specialityWithPosition.specialityFerId,
                  specialityFullName: getSpecialityFullName(
                    item.positionName,
                    specialityWithPosition.specialityName,
                    specialityWithPosition.specialityFerId,
                  ),
                  canServeAdults: speciality.canServeAdults,
                  canServeChildren: speciality.canServeChildren,
                });
              }
            });
          });
        });

        setSelectedSpecialities(tSelectedSpecialities);
      }
    } else {
      setSelectedSpecialities([createEmptySpeciality()]);
    }
  }, [fieldValues.speciality, specialitiesWithPosition]);

  const handleAddSpecialityOnClick = () => {
    setSelectedSpecialities((prev) => [...prev, createEmptySpeciality()]);
  };

  const handleDeleteSpecialityOnClick = (uid: string) => {
    setSelectedSpecialities((prev) => prev.filter((item) => item.uid !== uid));
  };

  const handleSetCanServeAdults = (uid: string, value: boolean) => {
    setSelectedSpecialities((prev) =>
      prev.map((item) => {
        if (item.uid === uid) {
          return {
            ...item,
            canServeAdults: value,
          };
        } else {
          return item;
        }
      }),
    );
  };

  const handleSetCanServeChildren = (uid: string, value: boolean) => {
    setSelectedSpecialities((prev) =>
      prev.map((item) => {
        if (item.uid === uid) {
          return {
            ...item,
            canServeChildren: value,
          };
        } else {
          return item;
        }
      }),
    );
  };

  const handleSpecialityOnChange = (uid: string, specialityPositionId: string) => {
    setSelectedSpecialities((prev) =>
      prev.map((item) => {
        if (item.uid === uid && item.specialityPositionId !== specialityPositionId) {
          const { specialityFerId, specialityName, positionName } = getOrganizationInfoBySpecialityPositionId(
            specialitiesWithPosition,
            specialityPositionId,
          )!;

          return {
            uid: item.uid,
            specializationId: undefined,
            specialityPositionId: specialityPositionId,
            canServeChildren: item.canServeChildren,
            canServeAdults: item.canServeAdults,
            specialityFullName: getSpecialityFullName(positionName, specialityName, specialityFerId),
          };
        } else {
          return item;
        }
      }),
    );
  };

  const isSzpvSync = useMemo((): boolean => {
    if (!branchesList) {
      return true;
    }

    const currentBranch = branchesList.find((branch) => branch.branchId === practiceId);
    if (currentBranch) {
      return !!currentBranch.useTmData;
    } else {
      return true;
    }
  }, [branchesList, practiceId]);

  if (errorDoctorGeneralInfo && pageMode === PageMode.Editing) {
    navigate(`/company/doctors/${practiceId ?? ''}`);
  }

  const handlePositionChange = (value: string) => {
    form.setFields([
      {
        name: 'position',
        value: value,
        errors: [],
      },
    ]);
  };

  return {
    isLoading,
    saveLoading,
    doctorGeneralInfo,
    practiceDoctorId,
    form,
    handleSubmitForm,
    setFieldValue,
    fieldValues,
    practiceId,
    genderLabel,
    gender,
    category,
    setGenderValue,
    setCategoryValue,
    degree,
    handleDoctorPhotoChange,
    checkPhotoType,
    setDegreeValue,
    prepareDegrees,
    handleDoctorPhotoRemove,
    handleChangePhoto,
    accessToDoctorPortalResult,
    hasAccessToDoctorPortal,
    resendVerificationEmailResult,
    resendVerificationEmail,
    handleEducationValuesChange,
    doctorContactInfo,
    prepareEducationTypes,
    handleDeleteEducationInfo,
    fileList,
    checkUploadDocType,
    handleDocumentSelection,
    handleCancelButtonOnClick,
    branchesList,
    handleDeleteDoctorFromBranch,
    doctorPhotoSrc,
    setDoctorPhotoFile,
    handleAccessToDoctorPortalClick,
    optionsSpecialities,
    selectedSpecialities,
    handleAddSpecialityOnClick,
    handleDeleteSpecialityOnClick,
    handleSetCanServeAdults,
    handleSetCanServeChildren,
    handleSpecialityOnChange,
    isSzpvSync,
    position,
    setPosition,
    positionsList,
    handlePositionChange,
  };
};
