import { Branch } from 'src/common/types';
import { BranchFilter } from '../../common/components/BranchFilter/BranchFilter';
import { Col, Row, Typography } from 'antd';
import { getPreparedBranches } from '../../common/utils/utils';
import { setServiceBranch } from 'src/app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  useGetClinicalResearchPracticeBranchQuery,
  useGetPracticeBranchesQuery,
  useGetWhiteLabelDataQuery
} from 'src/app/services/api';
import PatientsCabinetIcon from './Icons/PatientsCabinetIcon.svg';
import WhiteLabelIcon from './Icons/WhiteLabelIcon.svg';
import React, { ReactElement, useEffect, useMemo } from 'react';
import ServiceCard from './ServiceCard';
import TMKServiceIcon from './Icons/TMKServiceIcon.svg';
import WidgetServiceIcon from './Icons/WidgetServiceIcon.svg';
import ScheduleCabinetIcon from './Icons/ScheduleCabinetIcon.svg';
import ClinicResearchIcon from './Icons/ClinicResearchIcon.svg';
import styles from './styles.module.scss';
import { useAuth } from 'react-oidc-context';

function ServicesPage() {
  const dispatch = useAppDispatch();
  const serviceBranch = useAppSelector((state) => state.app.serviceBranchId);
  const availableFiltersBranches = useAppSelector((state) => state.app.availableBranches) || [];
  const role = useAppSelector((state) => state.app.role);

  const { user } = useAuth();

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const { data: clinicalResearchBranch } = useGetClinicalResearchPracticeBranchQuery('');
  const { data: whiteLabelData } = useGetWhiteLabelDataQuery('');

  const handleBranchSelect = (value: string) => {
    dispatch(setServiceBranch(value));
  };

  useEffect(() => {
    if (!serviceBranch) {
      if (branchesList && branchesList?.length > 0) {
        dispatch(setServiceBranch(getPreparedBranches(branchesList, availableFiltersBranches)?.[0]?.value));
      } else if (clinicalResearchBranch) {
        dispatch(setServiceBranch(getPreparedBranches([clinicalResearchBranch], availableFiltersBranches)?.[0]?.value));
      }
    }
  }, [serviceBranch, branchesList, clinicalResearchBranch]);

  const serviceCards = useMemo(() => {
    let tempCards: ReactElement[] = [];
    let selectedBranch: Branch | undefined;
    if (serviceBranch) {
      if (branchesList) {
        selectedBranch = branchesList.find((branch) => branch.branchId === serviceBranch);
      } else if (clinicalResearchBranch) {
        selectedBranch = clinicalResearchBranch;
      }
    }
    
    const isClinicalResearchRole = role === 4 || role === 5;

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Виджет онлайн-записи'}
          description={'Легко подключите онлайн-запись на ваш сайт. Управляйте дизайном виджета.'}
          imagePath={WidgetServiceIcon}
          serviceName={'widget'}
          isActive={
            selectedBranch
              ? selectedBranch?.isWidgetServiceEnabled
              : branchesList?.some((branch) => branch.isWidgetServiceEnabled)
          }
          hasSettings={!isClinicalResearchRole}
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Профиль клиники в Mila'}
          description={'Предоставьте пациентам доступ к их медкарте и сервисам от клиники\n \n'}
          imagePath={PatientsCabinetIcon}
          serviceName={'patient'}
          isActive={
            selectedBranch
              ? selectedBranch?.isShowInPatientAccountServiceEnabled
              : branchesList?.some((branch) => branch.isShowInPatientAccountServiceEnabled)
          }
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'White Label'}
          description={'Брендированная версия личного кабинета и виджета записи только для вас и ваших пациентов\n \n'}
          imagePath={WhiteLabelIcon}
          serviceName={'whiteLabel'}
          isActive={whiteLabelData?.isServiceEnabled}
          hasSettings={!isClinicalResearchRole}
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Телемедицина в Mila'}
          description={'Расширяйте географию услуг и платите только за проведенные консультации'}
          imagePath={TMKServiceIcon}
          serviceName={'tmk'}
          isActive={
            selectedBranch
              ? selectedBranch?.isTmkServiceEnabled
              : branchesList?.some((branch) => branch.isTmkServiceEnabled)
          }
          hasSettings={!isClinicalResearchRole}
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Расписание в Mila'}
          description={'Возможность вести расписание вручную внутри сервиса как альтернатива интеграции с МИС'}
          imagePath={ScheduleCabinetIcon}
          serviceName={'schedule'}
          isActive={
            selectedBranch
              ? selectedBranch?.isScheduleServiceEnabled
              : branchesList?.some((branch) => branch.isScheduleServiceEnabled)
          }
        />
      </Col>,
    );

    {
      tempCards.push(
      <Col>
        <ServiceCard
          title={'Клинические исследования'}
          description={'Электронное подписание пациентами согласий на участие в клинических исследованиях'}
          imagePath={ClinicResearchIcon}
          serviceName={'research'}
          isActive={selectedBranch
              ? selectedBranch?.isClinicalResearchServiceEnabled
              : branchesList?.some((branch) => branch.isClinicalResearchServiceEnabled)}
          hasSettings={isClinicalResearchRole}
        />
      </Col>,
    );
    }

    return tempCards;
  }, [branchesList, serviceBranch, whiteLabelData, clinicalResearchBranch, role]);

  return (
    <>
      <div className={styles.pageContainer}>
        <Col className={styles.pageTitleWrapper}>
          <Typography.Text className={styles.nameTitle}>Привет, {user?.profile.given_name}!</Typography.Text>
        </Col>
        <Row className={styles.infoBlock}>
          <Typography.Text>
            В этом личном кабинете вы можете подключить и настроить сервисы подсистемы Mila платформы N3.Health.
            Подсистема предназначена для работы медицинских организаций с пациентами. Посмотреть, как выглядит Mila
            глазами пациентов, можно на{' '}
            <a href={'https://my.mila.online'} target={'_blank'}>
              my.mila.online
            </a>
            , личный кабинет для врачей находится по адресу{' '}
            <a href={'https://doctor.mila.online'} target={'_blank'}>
              doctor.mila.online
            </a>
            .
          </Typography.Text>
        </Row>
        {serviceBranch && branchesList && branchesList?.length > 0 && (
          <Row>
            <BranchFilter onChange={handleBranchSelect} value={serviceBranch} addAll={false} />
          </Row>
        )}
        <div className={styles.pageContent}>
          <Row gutter={[47, 38]} className={styles.cardsContainer}>
            {serviceCards}
          </Row>
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
