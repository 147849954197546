import {Layout} from "antd";
import React from "react";
import {useGetSessionToDocGenByBranchIdQuery} from "src/app/services/api";
import TMKScheduleWidget from "./TMKScheduleWidget/TMKScheduleWidget";
import styles from './styles.module.scss';

export const SchedulePage = () => {

    const {data: sessionData, isFetching, isLoading} = useGetSessionToDocGenByBranchIdQuery(
        "",
        {refetchOnMountOrArgChange: true}
    );

    return (
        <Layout className={`${styles.ScheduleWrapper}`}>
            {
                sessionData && !isFetching && !isLoading &&
                <TMKScheduleWidget sessionId={sessionData}/>
            }
        </Layout>
    );
};
