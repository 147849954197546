import { Appointment, DictionaryItem } from 'src/common/types';
import { BranchFilter } from 'src/common/components/BranchFilter/BranchFilter';
import { ColumnsType } from 'antd/es/table';
import { ConfirmCircle } from 'src/common/components/ConfirmCircle/ConfirmCircle';
import { CustomTable } from 'src/common/components/CustomTable/CustomTable';
import { Row, Col, Layout } from 'antd';
import { Rating } from 'src/common/components/Rating/Rating';
import { RecordsStub } from 'src/common/components/RecordsStub/RecordsStub';
import { getAppointmentTime, getFsmsName, getPageVisibility } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import { useGetFerSpecialitiesQuery } from '../../../app/services/apiDictionary';
import { useGetPracticeAppointmentsQuery } from '../../../app/services/api';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';
import { CustomInput } from '../../../common/components/CustomInput/CustomInput';
import { useDebounce } from '../../../common/hooks/useDebouncedCall';

const columns: ColumnsType<Appointment> = [
  {
    title: 'ID записи',
    dataIndex: 'milaId',
    key: 'milaId',
    width: 107,
  },
  {
    title: 'Статус записи',
    dataIndex: 'status',
    key: 'status',
    width: 147,
  },
  {
    title: 'Создана',
    dataIndex: 'created',
    key: 'created',
    width: 147,
    ellipsis: true,
    sorter: (item1: Appointment, item2: Appointment) => item1.created.diff(item2.created),
    render: (val: Dayjs) => val.format('HH:mm DD.MM.YYYY'),
  },
  // {
  //   title: 'Синхр. с МИС.',
  //   dataIndex: 'isMisConfirmed',
  //   key: 'isMisConfirmed',
  //   width: 135,
  //   render: (value) => <ConfirmCircle confirmed={value} />,
  //   sorter: (item1: Appointment, item2: Appointment) =>
  //     item1.isMisConfirmed === item2.isMisConfirmed ? 0 : item1.isMisConfirmed ? -1 : 1,
  // },

  {
    title: 'Дата приёма',
    dataIndex: 'visitStartDate',
    key: 'visitStartDate',
    width: 132,
    sorter: (item1: Appointment, item2: Appointment) => item1.visitStartDate.diff(item2.visitStartDate),
    render: (val: Dayjs) => (val.isValid() ? val.format('DD.MM.YYYY') : '-'),
  },
  {
    title: 'Время',
    dataIndex: 'time',
    key: 'time',
    width: 116,
    render: (val, record) => getAppointmentTime(record.visitStartDate, record.visitEndDate),
    sorter: (item1: Appointment, item2: Appointment) =>
      item1.visitStartDate.format('HH:mm').localeCompare(item2.visitStartDate.format('HH:mm')),
  },
  {
    title: 'Пациент',
    dataIndex: 'patientFullName',
    key: 'patientFullName',
    width: 210,
    className: styles.cellLinkText,
    ellipsis: true,
    sorter: (item1: Appointment, item2: Appointment) => item1.patientFullName.localeCompare(item2.patientFullName),
  },
  {
    title: 'Дата рожд.',
    dataIndex: 'patientBirthDay',
    key: 'patientBirthDay',
    width: 129,
    sorter: (item1: Appointment, item2: Appointment) => item1.visitStartDate.diff(item2.visitStartDate),
    render: (val: Dayjs) => (val.isValid() ? val.format('DD.MM.YYYY') : '-'),
  },
  {
    title: 'Телефон',
    dataIndex: 'patientPhone',
    key: 'patientPhone',
    width: 155,
    sorter: (item1: Appointment, item2: Appointment) => item1.patientPhone.localeCompare(item2.patientPhone),
  },
  {
    title: 'E-mail',
    dataIndex: 'patientEmail',
    key: 'patientEmail',
    width: 156,
    className: styles.cellLinkText,
    ellipsis: true,
    sorter: (item1: Appointment, item2: Appointment) => item1.patientEmail.localeCompare(item2.patientEmail),
  },
  {
    title: 'Специалист',
    dataIndex: 'specialistFullName',
    key: 'specialistFullName',
    width: 210,
    ellipsis: true,
    sorter: (item1: Appointment, item2: Appointment) =>
      item1.specialistFullName.localeCompare(item2.specialistFullName),
  },
  {
    title: 'Специализация',
    dataIndex: 'specialty',
    key: 'specialty',
    width: 198,
    // sorter: (item1: Appointment, item2: Appointment) => item1.specialization.localeCompare(item2.specialization),
  },
  {
    title: 'Услуги',
    dataIndex: 'specializationServiceMisName',
    key: 'specializationServiceMisName',
    width: 166,
    sorter: (item1: Appointment, item2: Appointment) =>
      item1.specializationServiceMisName.localeCompare(item2.specializationServiceMisName),
  },
  {
    title: 'Цена, Р.',
    dataIndex: 'cost',
    key: 'cost',
    width: 98,
    sorter: (item1: Appointment, item2: Appointment) => item1.cost.localeCompare(item2.cost),
  },
  {
    title: 'Филиал',
    dataIndex: 'medOrganizationShortName',
    key: 'medOrganizationShortName',
    width: 175,
    ellipsis: true,
    sorter: (item1: Appointment, item2: Appointment) =>
      item1.medOrganizationShortName.localeCompare(item2.medOrganizationShortName),
  },
  {
    title: 'Оценка пользователя',
    dataIndex: 'doctorReview',
    key: 'doctorReview',
    render: (val) => <Rating score={val?.score} comment={val?.comment} />,
    sorter: (item1: Appointment, item2: Appointment) => item1.doctorReview?.score - item2.doctorReview?.score,
  },
];

const prepareRecordsTable = (data?: Appointment[], specialitiesDictionaryList?: DictionaryItem[]) => {
  return data
    ? data?.map((record: Appointment) => {
        return {
          ...record,
          created: dayjs(record.created),
          visitStartDate: dayjs(record.visitStartDate),
          visitEndDate: dayjs(record.visitEndDate),
          patientBirthDay: dayjs(record.patientBirthDay),
          specialty: getFsmsName(record.specialization, specialitiesDictionaryList),
        };
      })
    : [];
};

function AppointmentRequests() {
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [selectedBranchId, setSelectedBranchId] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');
  const [doctorName, setDoctorName] = useState<string>('');
  const [patientNameSearch, setPatientNameSearch] = useState<string>('');
  const [doctorNameSearch, setDoctorNameSearch] = useState<string>('');
  const debouncedSearchPatient = useDebounce(patientName?.trim() || '', 300);
  const debouncedSearchDoctor = useDebounce(doctorName?.trim() || '', 300);
  const [sort, setSort] = useState('created desc');

  const permissions = useAppSelector((state) => state.app.permissions) || [];

  const isRecordsVisible =
    getPageVisibility(permissions, 'can-access-page-records-clinic') ||
    getPageVisibility(permissions, 'can-access-page-appointments-clinic');

  const { data: recordsList } = useGetPracticeAppointmentsQuery({
    sortType: sort,
    offset: pageOffset,
    count: pageCount,
    branchId: selectedBranchId,
    patientName: patientNameSearch,
    doctorName: doctorNameSearch,
  });

  useEffect(() => {
    setPatientNameSearch(patientName);
  }, [debouncedSearchPatient]);

  useEffect(() => {
    setDoctorNameSearch(doctorName);
  }, [debouncedSearchDoctor]);

  const { data: specialitiesDictionaryList } = useGetFerSpecialitiesQuery('');

  const formattedRecords = useMemo(
    () => prepareRecordsTable(recordsList?.appointmentsBatch, specialitiesDictionaryList),
    [recordsList, specialitiesDictionaryList],
  );

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };
  const onSortChange: any = (pagination, filters, sorter, extra) => {
    if (sorter?.field && sorter?.order) {
      setSort(
        `${sorter.field !== 'time' ? sorter.field : 'visitStartDate'}${sorter.order === 'descend' ? ' desc' : ''}`,
      );
    }
  };

  const handleBranchSelect = (value: string) => {
    setSelectedBranchId(value);
  };

  return !isRecordsVisible ? (
    <RecordsStub
      title="Здесь будет список записей на прием"
      text="После настройки сервисов здесь будут видны все записи к специалистам, совершенные через виджет или личный кабинет пациентами клиники. Если вы считаете, что данные в этом разделе не отображаются по ошибке, пожалуйста, обратитесь в службу поддержки."
    />
  ) : (
    <Layout className={styles.pageContainer}>
      <Row className={styles.InputsRow}>
        <Col>
          <BranchFilter value={selectedBranchId} onChange={handleBranchSelect} />
        </Col>
        <CustomInput
          value={doctorName}
          onChange={(e) => setDoctorName(e.target.value)}
          floatLabel="ФИО врача"
          className={styles.Input}
          floatLabelClassName={styles.InputLabel}
        />
        <CustomInput
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
          floatLabel="ФИО пациента"
          className={styles.Input}
          floatLabelClassName={styles.InputLabel}
        />
      </Row>
      <CustomTable<Appointment>
        dataSource={formattedRecords}
        columns={columns}
        total={recordsList?.total}
        pageNumber={pageNumber}
        onPageChange={handlePageChange}
        onSortChange={onSortChange}
        className={styles.RecordsTable}
      />
    </Layout>
  );
}

export default AppointmentRequests;
