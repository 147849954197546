import 'react-quill/dist/quill.snow.css';
import { Branch, PromotionDoctors, PromotionFormValues } from 'src/common/types';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  List,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload,
  notification,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CustomDatePicker } from 'src/common/components/CustomDatePicker/CustomDatePicker';
import { CustomInput } from 'src/common/components/CustomInput/CustomInput';
import { DownCaretIcon } from 'src/common/Icons/icons';
import { ReactComponent as EditIcon } from 'src/common/Icons/EditIcon.svg';
import { ReactComponent as InfiniteIcon } from 'src/common/Icons/InfiniteIcon.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/QuestionMarkIcon.svg';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload';
import { getPreparedSortedBranches } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import {
  useCreateNewPromotionMutation,
  useGetPracticeBranchesQuery,
  useGetPromotionByIdQuery,
  useGetPromotionSpecializationsQuery,
  useGetPromotionsDoctorsQuery,
  useUpdatePromotionProfileDataMutation,
  useUploadPromotionProfileBackgroundMutation,
  useDeletePromotionProfileDataMutation,
} from '../../../app/services/api';
import { useForm } from 'antd/lib/form/Form';
import {useLocation, useNavigate} from 'react-router-dom';
import FallbackUserPhoto from '../../../common/Icons/FallbackUserPhoto.svg';
import FloatLabelInput from 'src/common/FloatLabelInput/FloatLabelInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextEditor from 'src/common/components/TextEditor/TextEditor';
import VirtualList from 'rc-virtual-list';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';

const getIsTimeLimitedValue = (value: boolean) => Number(value);

const getDoctorsIds = (doctors: PromotionDoctors[]) =>
  doctors.map((doctor: PromotionDoctors) => doctor.practiceDoctorId);

const renderDoctorSpecialities = (doctorItem: PromotionDoctors) => (
  <Col className={styles.SpecializationList}>
    {([...new Set(doctorItem.specializations)] as any).reduce((p: string, c: string, index: number) => [
      <Typography.Text key={index}>
        <Typography.Text>{p}</Typography.Text>
        <br />
        <Typography.Text>{c}</Typography.Text>
      </Typography.Text>,
    ])}
  </Col>
);

const INFINITE_DATE = 1;

export default function PromotionForm() {
  const navigate = useNavigate();
  const [form] = useForm<PromotionFormValues>();
  const [isSubmitDisabled, setSubmitBtnDisabled] = useState(true);
  const [isBranchToggled, setBranchIsToggled] = useState(false);
  const [typeDate, setTypeDate] = useState<number>(0);
  const [branchPublicInfoType, setBranchPublicInfoType] = useState<number>(0);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [bannerBgId, setBannerBgId] = useState<string>('');
  const [bannerImgUrl, setBannerImgUrl] = useState<string>('');
  const [bannerFile, setBannerFile] = useState<any>();
  const [doctorSpecialization, setDoctorSpecialization] = useState<string>('');
  const [selectedDoctors, setSelectedDoctors] = useState<PromotionDoctors[]>([]);
  const [branchesStateList, setBranchesStateList] = useState<string[]>([]);

  const { pathname } = useLocation();
  const promotionId = useMemo(() => pathname.split('/').reverse()[0], [pathname]);

  const availableBranches = useAppSelector((state) => state.app.availableBranches) || [];

  const isNewPromotionForm = useMemo(() => promotionId === 'promotionForm', [promotionId]);

  const { data: promotionData, refetch: refetchPromotionData } = useGetPromotionByIdQuery(promotionId, {
    skip: isNewPromotionForm,
  });
  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const { data: specializationsList } = useGetPromotionSpecializationsQuery('');
  const { data: doctorsList } = useGetPromotionsDoctorsQuery({
    specializationId: doctorSpecialization,
    branches: branchesStateList,
  });
  const [uploadPromotionProfileBackground, uploadBackgrounRequest] = useUploadPromotionProfileBackgroundMutation();
  const [updatePromotionProfileData, updatePromotionRequest] = useUpdatePromotionProfileDataMutation();
  const [deletePromotionProfileData, deletePromotionRequest] = useDeletePromotionProfileDataMutation();
  const [createNewPromotion, createPromotionRequest] = useCreateNewPromotionMutation();

  const currentSelectedDoctors = useMemo(() => {
    return (
      doctorsList?.filter((doctor: PromotionDoctors) =>
        promotionData?.participatingPracticeDoctorIds.includes(doctor.practiceDoctorId),
      ) || []
    );
  }, [doctorsList, promotionData?.participatingPracticeDoctorIds]);

  const setDefaultValues = useCallback(() => {
    if (promotionData) {
      form.setFieldsValue({
        header: promotionData.title,
        titleDescription: promotionData.titleDescription,
        buttonName: promotionData.buttonTitle,
        buttonUrl: promotionData.buttonUrl,
        detailedConditions: promotionData.description,
        startDate: !promotionData.isTimeLimited ? dayjs(promotionData.begins) : null,
        endDate: !promotionData.isTimeLimited ? dayjs(promotionData.ends) : null,
        showForPatients: promotionData.showForPatients,
      });
    }
  }, [promotionData, form]);

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableBranches) : []),
    [branchesList, availableBranches],
  );

  const specializationsOptions = useMemo(
    () =>
      specializationsList?.map((speciality: any) => ({
        label: speciality.specializationName,
        value: speciality.specializationId,
      })),
    [specializationsList],
  );
  
  const deletePromotion = () => {
    deletePromotionProfileData(promotionId)
        .unwrap()
        .then(() => {
          notification.success({ message: 'Акция успешно удалена' });
          navigate('/marketing/promotions/promotionsList?update=true');
        })
        .catch((error) => notification.error({ message: error.data.detail }));;
  };

  const handleSubmitForm = (v: PromotionFormValues) => {
    isNewPromotionForm
      ? createNewPromotion({
          title: v.header,
          titleDescription: v.titleDescription ?? "",
          buttonTitle: v.buttonName ?? "",
          buttonUrl: v.buttonUrl ?? "",
          description: v.detailedConditions ?? "",
          isTimeLimited: typeDate === INFINITE_DATE,
          begins: typeDate === INFINITE_DATE ? dayjs() : v.startDate,
          ends: typeDate === INFINITE_DATE ? null : v.endDate,
          participatingBranches: branchesStateList,
          participatingBranchesDisplayType: branchPublicInfoType,
          showInPatientPersonalAccount: v.showForPatients,
          participatingPracticeDoctorIds: getDoctorsIds(selectedDoctors),
        })
          .unwrap()
          .then((res) => {
            if (bannerFile) {
              uploadPromotionProfileBackground({
                file: bannerFile,
                promotionId: res,
              })
                .unwrap()
                .then((id: string) => {
                  setBannerFile(null);
                  setBannerBgId(id);
                  notification.success({ message: 'Новая акция успешно создана' });
                  navigate('/marketing/promotions/promotionsList?update=true');
                }).catch((error) => notification.error({ message: error.data.detail }));
            } else {
              notification.success({ message: 'Новая акция успешно создана' });
              navigate('/marketing/promotions/promotionsList?update=true');
            }
          })
          .catch((error) => notification.error({ message: error.data.detail }))
      : updatePromotionProfileData({
          title: v.header,
          titleDescription: v.titleDescription ?? "",
          buttonTitle: v.buttonName ?? "",
          buttonUrl: v.buttonUrl ?? "",
          description: v.detailedConditions ?? "",
          isTimeLimited: typeDate === INFINITE_DATE,
          begins: typeDate === INFINITE_DATE ? dayjs() : v.startDate,
          ends: typeDate === INFINITE_DATE ? null : v.endDate,
          participatingBranches: branchesStateList,
          participatingBranchesDisplayType: branchPublicInfoType,
          showForPatients: v.showForPatients,
          participatingPracticeDoctorIds: getDoctorsIds(selectedDoctors),
          promotionId: promotionId,
        })
          .unwrap()
          .then(() => {
            if (bannerFile) {
              uploadPromotionProfileBackground({
                file: bannerFile,
                promotionId: promotionId,
              })
                .unwrap()
                .then((id: string) => {
                  setBannerFile(null);
                  setBannerBgId(id);
                  refetchPromotionData();
                  notification.success({ message: 'Данные об акции успешно обновлены' });
                }).catch((error) => notification.error({ message: error.data.detail }));
            } else {
              refetchPromotionData();
              notification.success({ message: 'Данные об акции успешно обновлены' });
            }
          })
          .catch((error) => notification.error({ message: error.data.detail }));
  };

  const onFormValuesChange = () => {
    setSubmitBtnDisabled(false);
  };

  useEffect(() => {
    setDefaultValues();
    if (promotionData && !isBranchToggled) {
      setBranchesStateList(promotionData?.participatingBranches || []);

      if (specializationsList) {
        setSelectedDoctors(currentSelectedDoctors);
      }

      setBannerBgId(promotionData.backgroundImageId);
      setStartDate(dayjs(promotionData.begins));
      setEndDate(dayjs(promotionData.ends));
      setBranchPublicInfoType(promotionData.participatingBranchesDisplayType);
      setTypeDate(getIsTimeLimitedValue(promotionData.isTimeLimited));
    }
  }, [promotionData, specializationsList, currentSelectedDoctors]);

  const onDateChange = (e: RadioChangeEvent) => {
    setTypeDate(e.target.value);
    setSubmitBtnDisabled(false);
  };

  const onBranchPublicInfoChange = (e: RadioChangeEvent) => {
    setBranchPublicInfoType(e.target.value);
    setSubmitBtnDisabled(false);
  };

  const handleStartDateChange = (selectedDate: Dayjs | null) => {
    if (selectedDate) {
      setStartDate(selectedDate);
    }
  };

  const handleEndDateChange = (selectedDate: Dayjs | null) => {
    if (selectedDate) {
      setEndDate(selectedDate);
    }
  };

  const handleBgChange = (event: UploadChangeParam<UploadFile>) => {
    if (event.fileList[0]?.originFileObj) {
      setBannerBgId('');
      setBannerImgUrl(URL.createObjectURL(event.fileList[0]?.originFileObj));
      setBannerFile(event.fileList[0]?.originFileObj);
      setSubmitBtnDisabled(false);
    }
  };

  const handleChangeBackground = () => {
    document.getElementById('backgroundPromotionUpload')?.click();
  };

  const checkBgPhotoType = (file: RcFile) => {
    const isLt05M = file.size / 1024 / 1024 < 0.5;

    const isProperFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg' ||
      file.type === 'image/gif' ||
      file.type === 'image/svg+xml';
    if (!isLt05M) {
      notification.error({ message: 'Размер файла не должен превышать 500КБ!' });
    }
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
    }
    return isProperFormat && isLt05M || Upload.LIST_IGNORE;
  };

  const handleSpecializationSelector = (specId: string) => setDoctorSpecialization(specId);

  const handleBranchToggle = (branchId: string) => {
    setBranchIsToggled(true);
    if (branchesStateList?.includes(branchId)) {
      const updatedList = branchesStateList.filter((branchListId: string) => branchListId !== branchId);
      setBranchesStateList(updatedList);
    } else {
      const updatedList = [...branchesStateList];
      updatedList.push(branchId);
      setBranchesStateList(updatedList);
    }
  };

  const handleAddDoctor = (doctorEntity: PromotionDoctors) => {
    if (
      !selectedDoctors.some((doctor: PromotionDoctors) => doctor.practiceDoctorId === doctorEntity.practiceDoctorId) ||
      selectedDoctors.length === 0
    ) {
      setSelectedDoctors([...selectedDoctors, doctorEntity]);
      setSubmitBtnDisabled(false);
    }
  };

  const handleDeleteDoctor = (doctorId: string) => {
    const updatedDoctorsList = selectedDoctors.filter(
      (doctor: PromotionDoctors) => doctor.practiceDoctorId !== doctorId,
    );
    setSelectedDoctors(updatedDoctorsList);
    setSubmitBtnDisabled(false);
  };

  return (
    <Col className={styles.PageContainer}>
      <Row className={styles.TitlePromotionForm}>
        <Typography.Text>{isNewPromotionForm ? 'Добавить акцию' : 'Изменить акцию'}</Typography.Text>
      </Row>
      <Form form={form} layout="vertical" onFinish={handleSubmitForm} onValuesChange={onFormValuesChange}>
        <Col className={styles.GeneralDataContainer}>
          <Col className={styles.GeneralDataFormTitle}>
            <Typography.Text>Общие данные</Typography.Text>
          </Col>
          <Row gutter={24} className={styles.InputsRowWrapper}>
            <Row className={styles.SingleFormRow}>
              <Col>
                <Form.Item name="header" rules={[{ required: true, message: 'Пожалуйста заполните поле' }]}>
                  <CustomInput floatLabel="Заголовок *" className={styles.FormInput} maxLength={40} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="titleDescription">
                  <CustomInput floatLabel="Подпись под заголовком" className={styles.FormInput} maxLength={70} />
                </Form.Item>
              </Col>
            </Row>

            <Row className={styles.SingleFormRow}>
              <Col>
                <Form.Item name="buttonName">
                  <CustomInput floatLabel="Название кнопки" className={styles.FormInput} maxLength={30} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="buttonUrl">
                  <CustomInput floatLabel="URL для кнопки" className={styles.FormInput} />
                </Form.Item>
              </Col>
            </Row>
          </Row>

          <Row>
            <Col className={styles.TextEditorWrapper}>
              <Col className={styles.TextEditorInfoText}>
                <Typography.Text>Подробные условия акции</Typography.Text>
              </Col>
              <Form.Item name="detailedConditions">
                <TextEditor value={form.getFieldValue('detailedConditions')} placeholder="Подробные условия" />
              </Form.Item>
            </Col>
          </Row>

            <Row className={styles.BackgroundPhotoRow}>
              <Col>
                <Form.Item>
                  <Upload.Dragger
                    className={styles.FileBackgroundUploader}
                    accept={'.jpg, .jpeg, .svg, .png, .avif, .webp'}
                    maxCount={1}
                    onChange={(event) => handleBgChange(event)}
                    beforeUpload={(file) => checkBgPhotoType(file)}
                    showUploadList={{ showRemoveIcon: false }}
                    openFileDialogOnClick={false}
                    id="backgroundPromotionUpload"
                  >
                    {bannerBgId ? (
                      <Image
                        alt=""
                        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${bannerBgId}?inline=true`}
                        height={160}
                        width={240}
                        preview={false}
                      />
                    ) : bannerImgUrl ?
                        (
                            <Image
                                alt=""
                                src={`${bannerImgUrl}`}
                                height={126}
                                width={410}
                                preview={false}
                            />
                        ) :(
                      <Typography.Text>Загрузите фон</Typography.Text>
                    )}
                  </Upload.Dragger>
                </Form.Item>
                <div className={styles.BgPhotoIcons}>
                  <Tooltip
                    title={
                      'Размер загружаемого файла - не более 500 Кб. Размер изображения 410х120 пикселей, рекомендуемый формат - SVG, допустимы также PNG, GIF, JPEG'
                    }
                    placement="right"
                  >
                    <QuestionMarkIcon />
                  </Tooltip>

                  <EditIcon
                    onClick={() => {
                      handleChangeBackground();
                    }}
                  />
                </div>
              </Col>
            </Row>
        </Col>

        <Col className={styles.PromotionLifetimeDataContainer}>
          <Col className={styles.GeneralDataFormTitle}>
            <Typography.Text>Сроки проведения акции</Typography.Text>
          </Col>
          <Row className={styles.DatesRadioButtonsWrapper}>
            <Radio.Group onChange={onDateChange} value={typeDate}>
              <Radio value={0}>Срок ограничен</Radio>
              <Radio value={INFINITE_DATE}>
                <Col className={styles.InfiniteDateLabelWrapper}>
                  Бессрочная <InfiniteIcon />
                </Col>
              </Radio>
            </Radio.Group>
          </Row>
          <Row gutter={24} className={styles.InputsRowWrapper}>
            <Row className={styles.SingleFormRow}>
              <Col>
                <FloatLabelInput label="Начало действия *" value={startDate.toString()} isDisabled={typeDate === 1}>
                  <Form.Item
                    name="startDate"
                    rules={[{ required: typeDate === 0, message: 'Пожалуйста заполните поле' }]}
                    className={styles.DateFormInputWrapper}
                  >
                    <CustomDatePicker
                      value={startDate}
                      onChange={handleStartDateChange}
                      className={styles.FormInput}
                      disabled={typeDate === INFINITE_DATE}
                    />
                  </Form.Item>
                </FloatLabelInput>
              </Col>
              <Col>
                <FloatLabelInput label="Окончание действия *" value={endDate.toString()} isDisabled={typeDate === 1}>
                  <Form.Item
                    name="endDate"
                    dependencies={['startDate']}
                    className={styles.DateFormInputWrapper}
                    rules={[
                      { required: typeDate === 0, message: 'Пожалуйста заполните поле' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('startDate') <= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Дата окончания должна быть позже даты начала!'));
                        },
                      }),
                    ]}
                  >
                    <CustomDatePicker
                      value={endDate}
                      onChange={handleEndDateChange}
                      className={styles.FormInput}
                      disabled={typeDate === INFINITE_DATE}
                    />
                  </Form.Item>
                </FloatLabelInput>
              </Col>
            </Row>

            <Row className={styles.FormInfoText}>
              <Typography.Text>
                По окончании срока действия акция будет автоматически снята с публикации
              </Typography.Text>
            </Row>
          </Row>
        </Col>

        <Col className={styles.ParticipatingBranchesContainer}>
          <Col className={styles.GeneralDataFormTitle}>
            <Typography.Text>Участвующие филиалы</Typography.Text>
          </Col>
          <Row gutter={24} className={styles.InputsRowWrapper}>
            <Col className={styles.CheckboxesContainer} span={12}>
              {orderedBranches?.map((branch: Branch) => (
                <Col span={12} key={branch.branchId}>
                  <Form.Item name={branch.branchId} className={styles.BranchFormItem}>
                    <Checkbox
                      checked={branchesStateList?.includes(branch.branchId)}
                      onChange={(e) => {
                        handleBranchToggle(e.target.id!);
                      }}
                    >
                      {branch.shortName}
                    </Checkbox>
                  </Form.Item>
                </Col>
              ))}
            </Col>

            <Row className={styles.FormInfoText}>
              <Row className={styles.BranchesPublicInfoText}>
                <Typography.Text>
                  Определите, как публиковать информацию об участвующих филиалах на странице акции:
                </Typography.Text>
              </Row>
              <Row className={styles.BranchesRadioButtonsWrapper}>
                <Radio.Group onChange={onBranchPublicInfoChange} value={branchPublicInfoType}>
                  <Radio value={0}>На карте</Radio>
                  <Radio value={1}>Текстовым списком</Radio>
                  <Radio value={99}>Не публиковать информацию о филиалах</Radio>
                </Radio.Group>
              </Row>
            </Row>
          </Row>
        </Col>

        <Col className={styles.ParticipatingDoctorsContainer}>
          <Col className={styles.GeneralDataFormTitle}>
            <Typography.Text>Участвующие врачи</Typography.Text>
          </Col>
          <Row gutter={24} className={styles.InputsRowWrapper}>
            <Row className={styles.FormInfoText}>
              <Row className={styles.DoctorsPublicInfoText}>
                <Typography.Text>
                  Добавьте в акцию врачей. Карточки с ними будут опубликованы на странице акции.
                </Typography.Text>
                <Typography.Text>Пациенты смогут сразу выбрать врача и перейти к записи.</Typography.Text>
              </Row>
            </Row>
            <Row>
              <Col className={styles.SpecializationSelector}>
                <Select
                  className={styles.SpecializationInput}
                  onChange={handleSpecializationSelector}
                  value={doctorSpecialization}
                  suffixIcon={<DownCaretIcon />}
                  options={specializationsOptions}
                />
              </Col>
            </Row>
            {doctorsList?.length ? (
              <Row className={styles.DoctorsList}>
                {doctorsList && (
                  <List>
                    <VirtualList
                      data={doctorsList}
                      itemHeight={85}
                      itemKey="practiceDoctorId"
                      className={styles.DoctorsListContainer}
                    >
                      {(item: PromotionDoctors) => {
                        return selectedDoctors.find(
                          ({ practiceDoctorId }) => practiceDoctorId === item.practiceDoctorId,
                        ) ? (
                          <></>
                        ) : (
                          <List.Item key={item.practiceDoctorId}>
                            <List.Item.Meta
                              avatar={
                                <Image
                                  preview={false}
                                  src={
                                    item.photoStaticFileId
                                      ? `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${item.photoStaticFileId}?inline=true`
                                      : FallbackUserPhoto
                                  }
                                  className={styles.DoctorPhoto}
                                />
                              }
                              title={
                                <Typography.Text className={styles.DoctorNameText}>{item.fullName}</Typography.Text>
                              }
                            />
                            <Row className={styles.ContentWrapper}>
                              {renderDoctorSpecialities(item)}
                              <Col className={styles.AddButtonWrapper}>
                                <Button className={styles.AddButton} onClick={() => handleAddDoctor(item)}>
                                  Добавить
                                </Button>
                              </Col>
                            </Row>
                          </List.Item>
                        );
                      }}
                    </VirtualList>
                  </List>
                )}
              </Row>
            ) : (
              <Row className={styles.DoctorsListInfoText}>
                <Typography.Text>Для показа списка врачей должен быть выбран соответствующий филиал.</Typography.Text>
              </Row>
            )}
          </Row>
          {selectedDoctors.length > 0 && (
            <Row gutter={24} className={styles.InputsRowWrapper}>
              <Row className={styles.AddedDoctorsText}>
                <Typography.Text>Добавленные в акцию врачи</Typography.Text>
              </Row>
              <Row className={styles.SelectedDoctorsList}>
                <List
                  itemLayout="horizontal"
                  dataSource={selectedDoctors}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Image
                            preview={false}
                            src={
                              item.photoStaticFileId
                                ? `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${item.photoStaticFileId}?inline=true`
                                : FallbackUserPhoto
                            }
                            className={styles.DoctorPhoto}
                          />
                        }
                        title={<Typography.Text className={styles.DoctorNameText}>{item.fullName}</Typography.Text>}
                      />

                      <Row className={styles.selectedDoctorsContent}>
                        {renderDoctorSpecialities(item)}
                        <Col className={styles.RemoveButtonWrapper}>
                          <Popconfirm
                            title={`Вы уверены, что хотите убрать доктора из списка?`}
                            onConfirm={() => handleDeleteDoctor(item.practiceDoctorId)}
                            okText="Да"
                            cancelText="Нет"
                          >
                            <Button icon={<CloseOutlined />} className={styles.RemoveDoctorButton}></Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Row>
            </Row>
          )}
        </Col>

        <Col className={styles.VisibilityInServicesContainer}>
          <Col className={styles.GeneralDataFormTitle}>
            <Typography.Text>Отображение в сервисах</Typography.Text>
          </Col>
          <Col>
            <Form.Item name="showForPatients" className={styles.VisibilityChkbWrapper} valuePropName={'checked'}>
              <Checkbox checked={form.getFieldValue('showForPatients')}>Отображать для пациентов в Mila</Checkbox>
            </Form.Item>
          </Col>
        </Col>

        <Row className={styles.FormButtonsContainer} gutter={[10, 10]}>
          {promotionId &&<Col>
              <Popconfirm
                  title={`Удалить акцию?`}
                  okText="Удалить"
                  cancelText="Отмена"
                  onConfirm={deletePromotion}
              >
                <Button loading={deletePromotionRequest.isLoading} className={styles.delete}>Удалить акцию</Button>
              </Popconfirm></Col>}
          <Col>
            <Button className={styles.ButtonCancel} onClick={setDefaultValues}>
              Отмена
            </Button>
          </Col>
          <Col>
            <Button
              className={isSubmitDisabled ? styles.ButtonSubmitDisabled : styles.ButtonSubmit}
              htmlType="submit"
              disabled={isSubmitDisabled}
              loading={createPromotionRequest.isLoading || updatePromotionRequest.isLoading || uploadBackgrounRequest.isLoading}
            >
              Сохранить изменения
            </Button>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}
