import './App.scss';
import 'dayjs/locale/ru';
import { AboutService } from 'src/features/ServicesPage/AboutService/AboutService';
import { AddDoctorPage } from '../features/CompanyPage/DoctorsPage/AddDoctorPage/AddDoctorPage';
import { CallBackRequests } from 'src/features/RecordsPage/CallBackRequests/CallBackRequests';
import { ChatPage } from '../features/Chats/ChatPage';
import { ConfigProvider, Spin } from 'antd';
import { DoctorsInfoPage } from '../features/CompanyPage/DoctorsPage/DoctorInfoPage/DoctorsInfoPage';
import { ErrorPage } from 'src/common/components/ErrorPage/ErrorPage';
import { StatisticPage } from 'src/features/StatisticPage/StatisticPage';
import { NavWrapperComponent } from 'src/common/components/NavWrapperComponent/NavWrapperComponent';
import { NotificationPage } from 'src/features/Notifications/NotificationPage';
import { PageMode } from '../features/CompanyPage/DoctorsPage/DoctorInfoPage/DoctorsInfoPage.types';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { PromotionsPage } from 'src/features/MarketingPage/PromotionsPage/PromotionsPage';
import { Provider } from 'react-redux';
import { RecordsPage } from '../features/RecordsPage/RecordsPage';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import { SchedulePage } from 'src/features/Schedule/SchedulePage';
import { ServiceComponent } from 'src/features/ServicesPage/ServiceComponent/ServiceComponent';
import { ServicePage } from 'src/features/ServicesPage/ServicePage/ServicePage';
import { TmkRequests } from 'src/features/RecordsPage/TmkRequests/TmkRequests';
import { WaitingListRequests } from 'src/features/RecordsPage/WaitingListRequests/WaitingListRequests';
import { persistor, store } from './store';
import { useAppSelector } from './hooks';
import { useAuth } from 'react-oidc-context';
import AccessesPage from '../features/CompanyPage/AccessesPage/AccessesPage';
import AgreementsPage from '../features/CompanyPage/AgreementsPage/AgreementsPage';
import AmenitiesPage from '../features/CompanyPage/AmenitiesPage';
import AppointmentRequests from '../features/RecordsPage/AppointmentRequests/AppointmentRequests';
import BranchesPage from '../features/CompanyPage/BranchesPage/BranchesPage';
import PaymentsPage from '../features/CompanyPage/PaymentsPage/PaymentsPage';
import ChangePasswordPage from '../features/ChangePasswordPage/ChangePasswordPage';
import CompanyPage from '../features/CompanyPage/CompanyPage';
import DoctorAccessPage from '../features/CompanyPage/DoctorsPage/DoctorAccess/DoctorAccessPage';
import DoctorRecordsPage from '../features/CompanyPage/DoctorsPage/DoctorRecords/DoctorRecordsPage';
import DoctorsAmenitiesPage from '../features/CompanyPage/DoctorsPage/DoctorAmenities/DoctorAmenitiesPage';
import DoctorsListPage from '../features/CompanyPage/DoctorsPage/DoctorsListPage';
import Footer from '../features/Footer/Footer';
import Login from '../features/Login/Login';
import MarketingCardsPage from 'src/features/MarketingPage/MarketingCardsPage';
import NavBar from '../features/NavBar/NavBar';
import NetworkSelection from '../features/Login/NetworkSelection';
import PatientsPage from '../features/PatientsPage/PatientsPage';
import PromotionForm from 'src/features/MarketingPage/PromotionForm/PromotionForm';
import React, { useEffect } from 'react';
import ServicesPage from '../features/ServicesPage/ServicesPage';
import SpecializationsPage from '../features/CompanyPage/SpecializationsPage';
import UserAccessPage from '../features/CompanyPage/AccessesPage/UserAccessPage/UserAccessPage';
import WidgetSettingsPage from 'src/features/ServicesPage/WidgetPage/WidgetSettingsPage/WidgetSettingsPage';
import dayjs from 'dayjs';
import ruRU from 'antd/lib/locale/ru_RU';
import styles from '../features/CompanyPage/AccessesPage/styles.module.scss';
import utc from 'dayjs/plugin/utc';
import { YMInitializer } from 'react-yandex-metrika';
import WhiteLabelSettingsPage from '../features/ServicesPage/WhiteLabelPage/WhiteLabelSettingsPage/WhiteLabelSettingsPage';
import TmkSettingsPage from '../features/ServicesPage/TmkSettingsPage/TmkSettingsPage';
import { ScheduleSyncPage } from '../features/ScheduleSyncPage/ScheduleSyncPage';
import ResearchSettingsPage from '../features/ServicesPage/ResearchSettingsPage/ResearchSettingsPage';

dayjs.locale('ru');
dayjs.extend(utc);

const isAmoCrmFeatureEnabled = window.__RUNTIME_CONFIG__.REACT_APP_AMO_CRM_FEATURE_FLAG.toLowerCase() === 'true';
const amoLkLink: string = window.__RUNTIME_CONFIG__.REACT_APP_AMO_CRM_LK_URL;

export const ProtectedRoute = ({ children }: any) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        navigate('/login');
      } else if (!networkId && !medOrganizationId) {
        navigate('/networkSelection');
      }
    }
  }, [user, networkId, isLoading, navigate]);
  return isLoading ? <Spin size="large" className={styles.pageLoader} /> : children;
};

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <NavBar />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/',
          element: <ServicesPage />,
        },
        {
          path: '/services',
          element: <ServicesPage />,
        },
        {
          path: '/company',
          element: <CompanyPage />,
        },
        {
          path: '/statistic',
          element: <StatisticPage />,
        },
        {
          path: '/records',
          element: <RecordsPage />,
          children: [
            {
              path: 'appointments',
              element: <AppointmentRequests />,
            },
            {
              path: 'tmk',
              element: <TmkRequests />,
            },
            {
              path: 'waitingList',
              element: <WaitingListRequests />,
            },
            {
              path: 'callback',
              element: <CallBackRequests />,
            },
          ],
        },
        {
          path: '/marketing',
          element: <MarketingCardsPage />,
        },
        {
          path: '/marketing/promotions',
          element: <PromotionsPage />,
          children: [
            {
              path: ':promotionTab/:tab?',
              element: <NavWrapperComponent />,
            },
            {
              path: ':promotionsList/promotionForm/:id?',
              element: <PromotionForm />,
            },
          ],
        },
        {
          path: '/patients',
          element: <PatientsPage />,
        },
        {
          path: '/services/widget/settings',
          element: <ServiceComponent aboutServiceTab={<WidgetSettingsPage />} name="Виджет онлайн-записи" />,
        },
        {
          path: '/services/tmk/settings',
          element: <ServiceComponent aboutServiceTab={<TmkSettingsPage />} name="Телемедицина в Mila" />,
        },
        {
          path: '/services/research/settings',
          element: <ServiceComponent noSideBar={true} aboutServiceTab={<ResearchSettingsPage />} name="Клинические исследования" />,
          children: [
            {
              path: ':tab?'
            },
          ],
        },
        {
          path: '/services/whiteLabel/settings',
          element: <ServiceComponent aboutServiceTab={<WhiteLabelSettingsPage />} name="White Label" />,
        },
        {
          path: '/services',
          element: <ServicePage />,
          children: [
            {
              path: '/services/widget',
              element: (
                <ServiceComponent aboutServiceTab={<AboutService serviceName="widget" />} name="Виджет онлайн-записи" />
              ),
            },
            {
              path: '/services/patient',
              element: (
                <ServiceComponent
                  aboutServiceTab={<AboutService serviceName="patient" />}
                  name="Профиль клиники в Mila"
                />
              ),
            },
            {
              path: '/services/whiteLabel',
              element: (
                <ServiceComponent aboutServiceTab={<AboutService serviceName="whiteLabel" />} name="White Label" />
              ),
            },
            {
              path: '/services/tmk',
              element: (
                <ServiceComponent aboutServiceTab={<AboutService serviceName="tmk" />} name="Телемедицина в Mila" />
              ),
            },
            {
              path: '/services/schedule',
              element: (
                <ServiceComponent aboutServiceTab={<AboutService serviceName="schedule" />} name="Расписание в Mila" />
              ),
            },
            {
              path: '/services/research',
              element: (
                <ServiceComponent
                  aboutServiceTab={<AboutService serviceName="research" />}
                  name="Клинические исследования в Mila"
                />
              ),
            },
          ],
        },
        {
          path: '/company/branches',
          element: <BranchesPage />,
        },
        {
          path: '/company/specializations',
          element: <SpecializationsPage />,
        },
        {
          path: '/company/amenities',
          element: <AmenitiesPage />,
        },
        {
          path: '/company/payments',
          element: <PaymentsPage />,
        },
        // {
        //   path: '/company/accesses',
        //   element: <AccessesPage />,
        // },
        // {
        //   path: '/company/accesses/userAccesses',
        //   element: <UserAccessPage isNewUserMode />,
        // },
        {
          path: '/company/accesses/userAccesses/:userId',
          element: <UserAccessPage />,
        },
        {
          path: '/company/doctors',
          element: <DoctorsListPage />,
        },
        {
          path: '/company/doctors/:practiceId',
          element: <DoctorsListPage />,
        },
        {
          path: '/company/doctors/:practiceId/:id/general',
          element: <DoctorsInfoPage pageMode={PageMode.Editing} />,
        },
        {
          path: '/company/doctors/:practiceId/add-doctor',
          element: <AddDoctorPage />,
        },
        {
          path: '/company/doctors/:practiceId/:id/amenitiesDoctor',
          element: <DoctorsAmenitiesPage />,
        },
        {
          path: '/company/doctors/:practiceId/:id/records',
          element: <DoctorRecordsPage />,
        },
        {
          path: '/company/doctors/:practiceId/:id/access',
          element: <DoctorAccessPage />,
        },
        {
          path: '/company/agreements',
          element: <AgreementsPage />,
        },
        {
          path: '/changePassword',
          element: <ChangePasswordPage />,
        },
        { path: 'notifications', element: <NotificationPage /> },
        { path: 'chats', element: <ChatPage /> },
        { path: 'tm.schedule/*', element: <SchedulePage /> },
        { path: 'sync', element: <ScheduleSyncPage /> },
        {
          path: '*',
          element: (
            <ErrorPage
              title="Упс! Страница не найдена"
              text="Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
            />
          ),
        },
      ],
    },
    {
      path: '/login',
      index: true,
      element: <Login isAmoCrmLoginEnabled={isAmoCrmFeatureEnabled} startAmoLogin={false} />,
    },
    {
      path: 'login-with-amo',
      index: true,
      element: <Login isAmoCrmLoginEnabled={isAmoCrmFeatureEnabled} startAmoLogin={true} />,
    },
    {
      path: '/networkSelection',
      index: true,
      element: (
        <ProtectedRoute>
          <NetworkSelection isAmoCrmLoginEnabled={isAmoCrmFeatureEnabled} amoCrmLink={amoLkLink} />
        </ProtectedRoute>
      ),
    },
  ],
  { basename: `${process.env.PUBLIC_URL}/` },
);

function App() {
  useEffect(() => {
    console.log(`%cVersion: ${window.version}`, 'color: green; font-size: 20px;');
  }, []);
  return (
    <ConfigProvider
      locale={ruRU}
      theme={{
        token: {
          colorPrimary: '#6266a7',
          fontFamily: 'Ubuntu',
          colorText: '#50515B',
        },
        hashed: false,
      }}
    >
      <Provider store={store}>
        <PersistGate loading={<Spin size="large" className={styles.pageLoader} />} persistor={persistor}>
          <YMInitializer
            accounts={[96310613, 96143494]}
            options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
          />
          <div className="appContainer">
            <RouterProvider router={router} />
            <Footer />
          </div>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
